import {
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popper,
  Slide,
  Box,
} from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import { useStyles } from "./NavItem.styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import NavPopper from "../../../NavPopper/NavPopper";
import { useCallback, useMemo, useState } from "react";
import { NavItemPropsType } from "../../../../types/Components";
import { usePrizes } from "../../../../state/Prizes";

function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    (!!navigator?.maxTouchPoints && navigator.maxTouchPoints > 0)
  );
}

const NavItem: React.FC<NavItemPropsType> = ({
  path,
  translationKey,
  children,
  id,
  open,
  rotation,
  closePopover,
  openPopover,
  anchorEl,
  openKey,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { clearAllPrizeFilters } = usePrizes();

  const [toggle, setToggle] = useState<boolean>(false);

  const rotationStyle = useMemo(
    () => (openKey === translationKey ? { transform: rotation } : {}),
    [translationKey, openKey, rotation]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!!children) {
        e.preventDefault();
        e.stopPropagation();
        if (!toggle) {
          openPopover(e);
          setToggle(true);
        }
      } else {
        clearAllPrizeFilters();
      }
    },
    [children, openPopover, toggle, clearAllPrizeFilters]
  );

  const handleTitleClick = useCallback(() => {
    clearAllPrizeFilters();
    history.push(path);
    closePopover();
    setTimeout(() => setToggle(false), 200);
  }, [closePopover, history, path, clearAllPrizeFilters]);

  const handleClickAway = useCallback(() => {
    closePopover();
    setTimeout(() => setToggle(false), 200);
  }, [closePopover]);

  const handlePopperClose = useCallback(() => {
    closePopover();
    setTimeout(() => setToggle(false), 200);
  }, [closePopover]);

  return (
    <Box
      display="flex"
      onMouseLeave={isTouchDevice() ? undefined : closePopover}
    >
      <ListItem
        component={NavLink}
        to={path}
        className={classes.root}
        activeClassName={classes.active}
        exact
        disableGutters
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={isTouchDevice() ? undefined : openPopover}
        onClick={handleClick}
      >
        <ListItemText
          title={t(`menu.${translationKey}`)}
          disableTypography
          className={classes.itemText}
        >
          {t(`menu.${translationKey}`)}
        </ListItemText>
        {children && (
          <ListItemIcon className={classes.listItemIcon}>
            <ExpandMoreIcon
              style={rotationStyle}
              className={classes.itemIcon}
            />
          </ListItemIcon>
        )}
      </ListItem>
      {!!children && (
        <Popper
          open={open && openKey === translationKey}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          onMouseLeave={isTouchDevice() ? undefined : closePopover}
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener
              onClickAway={handleClickAway}
              mouseEvent="onMouseDown"
              touchEvent={false}
            >
              <Slide {...TransitionProps} timeout={250}>
                <Box>
                  <NavPopper
                    translationKey={translationKey}
                    closePopover={handlePopperClose}
                    children={children}
                    onTitleClick={handleTitleClick}
                    hideTitle
                  />
                </Box>
              </Slide>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
export default NavItem;
