import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "93px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
  },
  icon: {
    paddingBottom: theme.spacing(3),
  },
  no_results: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      paddingBottom: theme.spacing(1),
    },
  },
  info: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  link: {
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
    },
  },
}));
