import { makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxHeight: 132,
    maxWidth: 132,
    height: "100%",
    backgroundSize: "contain",
    margin: "0 auto",
  },
  divider: {
    height: "1.5px",
  },
  deleteIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "28px",
    marginRight: "-12px",
  },
  deleteIcon: {
    height: "10px",
    width: "10px",
  },
  cardContainer: {
    paddingBottom: "36px",
    paddingTop: "4px",
  },
  title: {
    fontSize: "12px",
    lineHeight: "19.2px",
    letterSpacing: "0.02em",
    fontFamily: "Helvetica Neue LT W05_75 Bold",
  },
  bold: {
    fontWeight: 700,
  },
  favoriteBtn: {
    marginLeft: "19px",
    padding: "5px",
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  addToCartBtn: {
    height: 48,
    padding: "10px 16px",
    marginTop: "12px",
    "& svg": {
      width: 28,
      height: 28,
      "& path": {
        fill: Colors.white,
      },
    },
    "& .MuiButton-startIcon": {
      marginRight: "16px",
    },
  },
  attributeOption: {
    fontSize: "12px",
    lineHeight: "19.2px",
    letterSpacing: "0.02em",
    color: Colors.middleGrey,
  },
}));
