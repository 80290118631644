import axios, { CancelToken } from "axios";

const ltDelimiter =
  process?.env.REACT_APP_LT_URL_DELIMITER ?? "randomStringThatWillNeverExist";
const lvDelimiter =
  process?.env.REACT_APP_LV_URL_DELIMITER ?? "randomStringThatWillNeverExist";
const eeDelimiter =
  process?.env.REACT_APP_EE_URL_DELIMITER ?? "randomStringThatWillNeverExist";

const acceptedLanguage = () => {
  const href = window.location.href;
  if (href.includes(ltDelimiter)) return "lt";
  if (href.includes(lvDelimiter)) return "lv";
  if (href.includes(eeDelimiter)) return "ee";
  return "lt";
};

export const instance = axios.create({
  baseURL: process?.env.REACT_APP_API_URL,
  headers: {
    "Accept-Language": acceptedLanguage(),
  },
});

export const hasAuthHeader = () => {
  return instance.defaults.headers.common["Authorization"] !== "";
};

export const setAuthHeader = async (token: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return;
};

export const mutateData = (
  type: "patch" | "delete" | "post" | "put" | "get",
  url: string,
  data?: any,
  headers?: {
    "Content-Type"?: string;
    "Access-Control-Allow-Origin"?: string;
  },
  responseType?: any,
  onUploadProgress?: (progressEvent: any) => void,
  cancelToken?: CancelToken
) => {
  return instance({
    method: type,
    url: url,
    data,
    headers,
    responseType: responseType,
    onUploadProgress: onUploadProgress,
    cancelToken: cancelToken,
  });
};

const fetcher = (url: string) => instance.get(url).then((res) => res.data);

export const SWROptions = {
  fetcher,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
