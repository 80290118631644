import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import NoResultsFound from "../../components/NoResultsFound/NoResultsFound";
import { useFavorites } from "../../state/Favorites";
import { ReactComponent as LoupeIcon } from "../../assets/icons/loupe.svg";
import TitleWithPoints from "../../components/TitleWithPoints/TitleWithPoints";
import { useProfileBriefInfo } from "../../state/Profile";
import PrizeListDesktop from "../../components/PrizeListDesktop/PrizeListDesktop";
import PrizeListMobile from "../../components/PrizeListMobile/PrizeListMobile";
import { useFavoritesTableHead } from "../../constants/favorites";
import { usePaths } from "../../constants/routes";

const Favorites = () => {
  const { t } = useTranslation();
  const { favoritePrizes, moveToCart, deleteFromFavorites } = useFavorites();
  const { profile } = useProfileBriefInfo();
  const accountPoints = useMemo(() => profile?.accountBalance ?? 0, [profile]);
  const thead = useFavoritesTableHead();
  const paths = usePaths();

  const List = useMemo(() => {
    if (!favoritePrizes)
      return (
        <NoResultsFound
          path={paths.prizes.replace("/:category?", "")}
          icon={<LoupeIcon />}
          info={t("errors.no_favorites")}
          link={t("common.check_prizes")}
        />
      );

    return (
      <>
        <PrizeListDesktop
          thead={thead}
          prizes={favoritePrizes}
          addToHandler={moveToCart}
          handleDelete={deleteFromFavorites}
          type="favorites"
        />
        <PrizeListMobile
          prizes={favoritePrizes}
          handleDelete={deleteFromFavorites}
          addToHandler={moveToCart}
          type="favorites"
        />
      </>
    );
  }, [favoritePrizes, paths.prizes, t, thead, moveToCart, deleteFromFavorites]);

  return (
    <Box pb={10}>
      <TitleWithPoints title="menu.favorites" accountPoints={accountPoints} />
      {List}
    </Box>
  );
};
export default Favorites;
