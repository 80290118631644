import { useCallback } from "react";
import { ReactComponent as CartIcon } from "../assets/icons/cartIcon.svg";
import { ReactComponent as FullCartIcon } from "../assets/icons/blue-cart.svg";
import { ReactComponent as HeartIcon } from "../assets/icons/heart.svg";
import { ReactComponent as FullHeartIcon } from "../assets/icons/blue-heart.svg";
import { ReactComponent as UserIcon } from "../assets/icons/profile.svg";
import { ReactComponent as FullUserIcon } from "../assets/icons/blue-user.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/lang.svg";
import { ReactComponent as FullGlobeIcon } from "../assets/icons/blue-globe.svg";

export const useHeaderUtil = () => {
  const getIconByType = useCallback(
    (type: "bag" | "favorites" | "user" | "languages") => {
      switch (type) {
        case "bag":
          return CartIcon;
        case "favorites":
          return HeartIcon;
        case "user":
          return UserIcon;
        case "languages":
          return GlobeIcon;
        default:
          return null;
      }
    },
    []
  );

  const getFullIconByType = useCallback(
    (type: "bag" | "favorites" | "user" | "languages") => {
      switch (type) {
        case "bag":
          return FullCartIcon;
        case "favorites":
          return FullHeartIcon;
        case "user":
          return FullUserIcon;
        case "languages":
          return FullGlobeIcon;
        default:
          return null;
      }
    },
    []
  );

  return {
    getFullIconByType,
    getIconByType,
  };
};
