import { useCallback } from "react";
import { TUtagAction, TUtagCustomer } from "../types/Utag";

export const useUtag = () => {
  const setUtagCustomer = useCallback((customer: TUtagCustomer) => {
    if (window.utag_data) {
      window.utag_data = {
        ...window.utag_data,
        ...customer,
      };
    }
  }, []);

  const linkUtagAction = useCallback((action: TUtagAction) => {
    if (window.utag) {
      window.utag.link({
        event_type: "action",
        event_goal: "true",
        product_currency: "eur",
        cart_currency: "eur",
        ...action,
      });
    }
  }, []);

  const clearUtag = useCallback(() => {
    window.utag_data = {};
  }, []);

  return {
    setUtagCustomer,
    linkUtagAction,
    clearUtag,
  };
};
