import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { useStyles } from "./DialogLayout.styles";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross-icon-grey.svg";

interface IProps extends DialogProps {
  title: string;
  onClose: () => void;
  open: boolean;
  onActionClick?: () => void;
  buttonTitle?: string;
  children: React.ReactNode;
  subTitle?: string | JSX.Element;
  subTitleStyle?: CSSProperties;
  size?: "large" | "medium";
}

export default function DialogLayout(props: IProps) {
  const {
    open,
    onClose,
    title,
    onActionClick,
    buttonTitle,
    subTitle,
    subTitleStyle = {},
    size = "medium",
    children,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      classes={{ paper: classes.dialogPaper }}
    >
      <Box display="flex" justifyContent="flex-end" pb={3}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
        <Box>
          <Typography
            className={clsx(classes.description, {
              [classes.descriptionLarge]: size === "large",
            })}
            variant={isMobile ? "body2" : "body1"}
            style={subTitleStyle}
          >
            {subTitle}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      {onActionClick && (
        <DialogActions className={classes.action}>
          <Button variant="contained" onClick={onActionClick}>
            {t(buttonTitle || "")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
