import {
  Collapse,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Divider,
  ClickAwayListener,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menuIcon.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import MobileNavMenuList from "./MobileNavMenuList/MobileNavMenuList";
import { useStyles } from "./MobileNavMenu.styles";
import { ReactComponent as RoundArrow } from "../../../assets/icons/roundArrow.svg";
import { MobileMenusType } from "../../../types/Components";
import useLinksUtil from "../../../utils/Links";
import { enableBodyScroll } from "../../../utils/Common";
import { usePrizes } from "../../../state/Prizes";

const MobileNavMenu: React.FC<MobileMenusType> = ({
  openMobileMenu,
  toggleMobileMenu,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navLinks } = useLinksUtil();
  const location = useLocation();
  const { clearAllPrizeFilters } = usePrizes();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleClose = useCallback(() => {
    clearAllPrizeFilters();
    toggleMobileMenu();
    setExpanded(false);
    enableBodyScroll();
  }, [toggleMobileMenu, clearAllPrizeFilters]);

  const onMenuClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onMenuClickAway = useCallback(
    (e) => {
      if (openMobileMenu) {
        e.preventDefault();
        e.stopPropagation();
        handleClose();
      }
    },
    [handleClose, openMobileMenu]
  );

  const handleChange = useCallback((panel: string) => {
    setExpanded((prev) => (prev !== panel ? panel : false));
  }, []);

  useEffect(() => {
    enableBodyScroll();
  }, [location?.pathname]);

  const navMenuMap = useMemo(
    () =>
      navLinks.map((link, i) => {
        const disableLink = link.key === "prizes";
        return (
          <Accordion
            key={link.key}
            square
            elevation={0}
            expanded={expanded === `panel${i + 1}`}
            className={classes.accordionRoot}
          >
            <AccordionSummary
              expandIcon={
                link.children && (
                  <ExpandMoreIcon
                    onClick={() => handleChange(`panel${i + 1}`)}
                  />
                )
              }
              aria-controls={`panel${i + 1}a-content`}
              id={`panel${i + 1}bh-header`}
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
              }}
            >
              {disableLink ? (
                <Typography
                  className={classes.title}
                  onClick={() => handleChange(`panel${i + 1}`)}
                >
                  <RoundArrow
                    className={classes.roundArrow}
                    style={{
                      width: expanded === `panel${i + 1}` ? "18px" : 0,
                      marginRight: expanded === `panel${i + 1}` ? "16px" : 0,
                    }}
                  />
                  {t(`menu.${link.key}`)}
                </Typography>
              ) : (
                <NavLink
                  to={link.path}
                  className={classes.title}
                  exact
                  activeClassName={classes.active}
                  onClick={onMenuClose}
                >
                  <RoundArrow
                    className={classes.roundArrow}
                    style={{
                      width: expanded === `panel${i + 1}` ? "18px" : 0,
                      marginRight: expanded === `panel${i + 1}` ? "16px" : 0,
                    }}
                  />
                  {t(`menu.${link.key}`)}
                </NavLink>
              )}
            </AccordionSummary>
            {link.children && (
              <AccordionDetails
                classes={{ root: classes.childAccordionDetails }}
              >
                <MobileNavMenuList
                  translationKey={link.key}
                  closePopover={onMenuClose}
                  subroutes={link.children}
                />
              </AccordionDetails>
            )}
          </Accordion>
        );
      }),
    [
      classes.accordionRoot,
      classes.accordionSummaryContent,
      classes.accordionSummaryRoot,
      classes.active,
      classes.childAccordionDetails,
      classes.roundArrow,
      classes.title,
      expanded,
      handleChange,
      onMenuClose,
      navLinks,
      t,
    ]
  );

  return (
    <>
      <Box onClick={toggleMobileMenu} className={classes.button}>
        <MenuIcon />
      </Box>
      <Collapse
        in={openMobileMenu}
        classes={{ root: classes.root }}
        unmountOnExit
      >
        <Divider />
        <ClickAwayListener onClickAway={onMenuClickAway}>
          <Paper elevation={1} className={classes.menuPaper}>
            <Box className={classes.menuBoxWrapper}>{navMenuMap}</Box>
          </Paper>
        </ClickAwayListener>
      </Collapse>
    </>
  );
};

export default MobileNavMenu;
