import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: Colors.white,
      padding: "16px 24px 24px 24px",
      width: "375px",
      marginTop: "8px",
      position: "relative",
      borderBottom: `3px solid ${Colors.darkBlue}`,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: (innerHeight) =>
          innerHeight > 687 ? "33px 32px 32px 32px" : "24px",
        borderBottom: "none",
      },
    },
    noItems: { borderBottom: "none" },
    divider: {
      backgroundColor: Colors.lightGreyDivider,
    },
  })
);
