export function storeData(data: any, key: string) {
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(data));
}

export function getData(key: string) {
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }
  var item = localStorage.getItem(key);

  if (!item) {
    return;
  }

  return JSON.parse(item);
}

export function removeData(key: string) {
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }
  localStorage.removeItem(key);
}

export function storeSessionData(data: any, key: string) {
  if (!window.sessionStorage || !window.JSON || !key) {
    return;
  }
  sessionStorage.setItem(key, JSON.stringify(data));
}
export function getSessionData(key: string) {
  if (!window.sessionStorage || !window.JSON || !key) {
    return;
  }
  var item = sessionStorage.getItem(key);
  if (!item) {
    return;
  }
  return JSON.parse(item);
}
export function removeSessionData(key: string) {
  if (!window.sessionStorage || !window.JSON || !key) {
    return;
  }
  sessionStorage.removeItem(key);
}
