import {
  Button,
  Container,
  Grid,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "./TopHeader.styles";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useAuth } from "../../../state/Auth";
import { ActionIcon } from "../ActionIcon/ActionIcon";
import HeaderPopper from "../HeaderPopper/HeaderPopper";
import LanguagesList from "../LanguagesList/LanguagesList";
import { ActivePopperType } from "../../../types/Components";
import { usePaths } from "../../../constants/routes";
import { useProfileBriefInfo } from "../../../state/Profile";

interface Props {
  handleIconHover: (type: string, hover: boolean) => void;
  activePopper: string;
  hoveredIcon: string;
  openLanguagePopper: boolean;
  onClose: () => void;
  handleIconClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type: ActivePopperType
  ) => void;
  anchorEl: any;
}

const Top = ({
  handleIconHover,
  activePopper,
  hoveredIcon,
  openLanguagePopper,
  onClose,
  handleIconClick,
  anchorEl,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const paths = usePaths();
  const { isBasfUser } = useProfileBriefInfo();

  const onHomeRedirect = useCallback(() => {
    history.push(isLoggedIn ? paths.home : paths.landing);
  }, [history, paths, isLoggedIn]);

  const onInvoiceGet = useCallback(() => {
    history.push(paths.invoice);
  }, [history, paths.invoice]);

  return (
    <Toolbar
      className={classes.root}
      style={{
        zIndex: !isLoggedIn ? undefined : 10,
      }}
    >
      <Container disableGutters>
        <Grid justifyContent="space-between" alignItems="center" container>
          <Logo className={classes.icon} onClick={onHomeRedirect} />
          {isLoggedIn && isBasfUser && (
            <>
              <Button
                color="secondary"
                variant="contained"
                startIcon={isMobile ? undefined : <Add />}
                className={classes.btn}
                onClick={onInvoiceGet}
              >
                {t("buttons.add_invoice")}
              </Button>
              {/* <Hidden mdUp>
                <IconButton
                  onClick={onInvoiceGet}
                  className={classes.mobileBtn}
                >
                  <AddCircle fontSize="large" color="secondary" />
                </IconButton>
              </Hidden> */}
            </>
          )}
          {!isLoggedIn && (
            <>
              <ActionIcon
                isActive={
                  (activePopper === "languages" && openLanguagePopper) ||
                  hoveredIcon === "languages"
                }
                type={"languages"}
                handleIconClick={(e) => handleIconClick(e, "languages")}
                handleHover={(hover) => handleIconHover("languages", hover)}
                badge={false}
              />
              <HeaderPopper
                open={openLanguagePopper}
                anchorEl={anchorEl}
                onBackdropClick={onClose}
                key="languages-popper"
              >
                <LanguagesList translationKey={"languages"} onClose={onClose} />
              </HeaderPopper>
            </>
          )}
        </Grid>
      </Container>
    </Toolbar>
  );
};

export default Top;
