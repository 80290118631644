import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: "26px 0 12px 0",
      position: "relative",
      borderBottom: `1.5px solid ${Colors.cardDivider}`,
      [theme.breakpoints.down("xs")]: {
        padding: "32px 0 16px 0",
      },
    },
    img: {
      height: "88px",
      minWidth: "79px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "contain",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "74px",
        minWidth: "67px",
      },
    },
    wrapper: {
      paddingLeft: "22px",
      width: "100%",
      paddingRight: (isScroll) => (isScroll > 3 ? "5px" : 0),
      [theme.breakpoints.down("xs")]: {
        paddingLeft: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    title: {
      fontWeight: 500,
      display: "flex",
      width: "100%",
      color: Colors.copyTextGrey,
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        lineHeight: "17px",
        fontSize: "12px",
      },
    },
    quantity: {
      fontWeight: 400,
      lineHeight: "14px",
      color: Colors.subtitleGrey,
    },
    points: {
      fontWeight: 500,
      lineHeight: "29px",
      [theme.breakpoints.down("xs")]: {
        lineHeight: "25px",
        fontSize: "14px",
      },
    },
    pointContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "12px",
      [theme.breakpoints.down("xs")]: {
        padding: "18px 35px 0 0",
      },
    },
    closeBtn: {
      position: "absolute",
      right: (isScroll) => (isScroll > 3 ? "2px" : 0),
      top: 3,
      padding: 6,
      [theme.breakpoints.down("xs")]: {
        "& span": {
          "& svg": {
            height: "12px",
            width: "12px",
          },
        },
        lineHeight: "25px",
        top: "12px",
      },
    },
  })
);
