import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      right: 80,
      bottom: "17px",
      zIndex: 3,
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        padding: "0 24px 0 24px",
      },
      [theme.breakpoints.down("xs")]: {
        right: 0,
      },
    },
    btn: {
      width: "62px",
      height: "62px",
      background: Colors.buttonBlue,
      "&:hover": {
        background: Colors.basfBlue,
      },
      [theme.breakpoints.down("sm")]: {
        width: "48px",
        height: "48px",
      },
    },
    formContent: {
      width: 360,
      [theme.breakpoints.down("sm")]: {
        width: 211,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
      },
    },
    questionForm: {
      width: "inherit",
      minHeight: "306px",
      marginBottom: theme.spacing(1),
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transition: "opacity .3s ease-out",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 12px 16px 12px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    popperMobile: {
      zIndex: 9999,
      [theme.breakpoints.down("xs")]: {
        width: "97%",
        transform: "none",
        height: "80vh",
        marginBottom: "1vh",
      },
    },
    paperMobile: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
      },
    },
    dialogPaper: {
      width: "100%",
      position: "relative",
      overflow: "hidden",
    },
    closeIconButton: {
      position: "absolute",
      top: 10,
      right: 10,
      "& svg path": {
        fill: Colors.darkBlue,
      },
    },
    dialogContainer: {
      overflow: "hidden",
    },
  })
);
