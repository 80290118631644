import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
  icon: {
    cursor: "pointer",
  },
  btn: {
    minWidth: "193px",
    border: `1px solid ${Colors.white}`,
    backgroundColor: Colors.white,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "150px",
      minWidth: "auto",
      padding: "12px 15px",
      marginRight: -5,
    },
  },
  mobileBtn: {
    paddingRight: 0,
  },
}));
