import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      lineHeight: "39px",
      paddingBottom: "8px",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "80px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingTop: "30px",
    },
  },
  accountPoints: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "30px",
    },
  },
  pointsTitle: {
    paddingRight: "8px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "30px",
    },
  },
}));
