import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./HeaderCountdown.styles";
import { useCountdown } from "../../../hooks/useCountdown";
import { getTimeValue } from "../../../utils/Countdown";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const HeaderCountdown = ({
  title,
  target,
  warningMode,
}: {
  title: string;
  target: number;
  warningMode?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [days, hours, minutes, seconds] = useCountdown(target);
  const displayTime = `
    ${days > 0 ? `${getTimeValue(days)} ${t("common.days_abbr")}` : ""}
    ${
      days <= 0 && hours <= 0
        ? ""
        : `${getTimeValue(hours)} ${t("common.hours_abbr")}`
    }
    ${
      days <= 0 && hours <= 0 && minutes <= 0
        ? ""
        : `${getTimeValue(minutes)} ${t("common.minutes_abbr")}`
    }
    ${getTimeValue(seconds)} ${t("common.seconds_abbr")}
  `;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="baseline"
      className={clsx(classes.root, { [classes.warning]: warningMode })}
    >
      <Typography
        variant="body1"
        className={clsx(classes.title, { [classes.warningTitle]: warningMode })}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={clsx(classes.time, { [classes.warningTime]: warningMode })}
      >
        {displayTime}
      </Typography>
    </Grid>
  );
};

export default HeaderCountdown;
