import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalLarge: {
    overflowX: "auto",
  },
  paper: {
    position: "relative",
    maxWidth: "552px",
    width: "100%",
    padding: "32px",
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      padding: "24px 24px 32px 24px",
      maxWidth: "311px",
    },
  },
  paperLarge: {
    maxWidth: "1104px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "622px",
      overflowY: "auto",
    },
  },
  closeButton: {
    paddingBottom: "24px",
    "& button": {
      margin: "-12px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "33px",
    },
  },
  description: {
    maxWidth: "70%",
    margin: "12px auto 0",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: "16px",
    },
  },
  icon: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
    "& svg": {
      [theme.breakpoints.down("xs")]: {
        width: "74px",
        height: "74px",
      },
    },
  },
  submitButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    "& button": {
      minWidth: 168,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "33px",
      "& button": {
        width: "100%",
      },
    },
  },
  submitButtonLarge: {
    marginBottom: 10,
    "& button": {
      fontSize: 32,
      height: 70,
      minWidth: 320,
    },
    [theme.breakpoints.down("xs")]: {
      "& button": {
        minWidth: "90%",
        fontSize: "18px",
        height: 50,
      },
    },
  },
  titleLarge: {
    fontSize: "48px",
    lineHeight: "52px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "36px",
    },
  },
  descriptionLarge: {
    maxWidth: "100%",
    fontSize: "32px",
    lineHeight: "36px",
    marginTop: "20px",
    marginBottom: "20px",
    "& a": {
      fontSize: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      marginTop: 20,
      marginBottom: 0,
      lineHeight: "28px",
      "& a": {
        fontSize: "24px",
      },
    },
  },
}));
