import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: (innerHeight) =>
        innerHeight > 630 ? "64px 0 0 0" : "32px 0 0 0",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      marginBottom: (innerHeight) => (innerHeight > 630 ? "24px" : "16px"),
      "& svg": {
        width: (innerHeight) => (innerHeight > 630 ? "unset" : "45px"),
        height: (innerHeight) => (innerHeight > 630 ? "unset" : "45px"),
      },
    },
    button: {
      marginTop: (innerHeight) => (innerHeight > 630 ? "96px" : "32px"),
      height: (innerHeight) => (innerHeight > 630 ? 48 : 40),
      background: theme.palette.primary.main,
      fontSize: theme.spacing(2),
      textTransform: "none",
    },
    noResults: {
      paddingBottom: "12px",
      fontSize: "16px",
    },
  })
);
