import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "white",
    padding: "0 24px 8px 24px",
    borderBottom: "3px solid rgba(0,74,150,1)",
    width: "auto",
    outline: "none !important",
    border: "none !important",
  },
}));
