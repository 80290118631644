import { useProfileBriefInfo } from "./Profile";
import { Endpoints } from "../api/endpoints";
import useSWR from "swr";
import { useMemo } from "react";
import {
  UseCategories,
  UseProducts,
  ProductsDescription,
} from "../types/Products";
import { useSWRInfinite } from "swr/esm";

export const useProducts = (
  id?: number,
  subCategoryId?: number
): UseProducts => {
  const { data: products, error: productsError } = useSWRInfinite((index) =>
    subCategoryId && id
      ? `${Endpoints.product}/${id}/${subCategoryId}`
      : !!id
      ? `${Endpoints.product}/${id}`
      : Endpoints.product
  );

  const loading = useMemo(
    () => !products && !productsError,
    [products, productsError]
  );

  return {
    products: products && products[0]?.products,
    productsError,
    loading,
  };
};

export const useCategories = (): UseCategories => {
  const { isBasfUser } = useProfileBriefInfo();
  const { data: categories, error: categoriesError } = useSWR(
    isBasfUser ? Endpoints.productCategories : null
  );

  const loading = useMemo(
    () => !categories && !categoriesError,
    [categories, categoriesError]
  );

  return {
    categories: categories?.categories,
    categoriesError,
    loading,
  };
};

export const useProductsDescription = () => {
  const { data: description, error } = useSWR<ProductsDescription, any>(
    Endpoints.productsDescription
  );

  const loading = useMemo(() => !description && !error, [description, error]);

  return { description, error, loading };
};
