import { Box, BoxProps } from "@material-ui/core";

import { useStyles } from "./FullWidthLayout.styles";
import clsx from "clsx";

interface IProps extends BoxProps {
  children: React.ReactNode;
  disableBorder?: boolean;
}
export default function FullWidthLayout({
  children,
  disableBorder,
  ...rest
}: IProps) {
  const classes = useStyles();
  return (
    <Box
      className={clsx({
        [classes.container]: true,
        [classes.topBorder]: !disableBorder,
      })}
      {...rest}
    >
      {children}
    </Box>
  );
}
