import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) => ({
  couponInput: {
    width: "100%",
  },
  applyCoupon: {
    minWidth: 175,
    "&:hover": {
      color: Colors.white,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
      minWidth: 100,
    },
  },
  couponCode: {
    fontWeight: 700,
    marginLeft: "4px",
  },
}));
