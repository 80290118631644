import { useCallback, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/endpoints";
import { UseFavorites } from "../types/Favorites";
import { mutateData } from "../api/api";
import { useAuth } from "./Auth";
import { useUtag } from "./Utag";
import { CartPrize } from "../types/Cart";

export const useFavorites = (): UseFavorites => {
  const { isLoggedIn } = useAuth();
  const {
    data: favorites,
    error: favoritesError,
    mutate: mutateFavorites,
  } = useSWR(isLoggedIn ? Endpoints.favorites : null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loading = useMemo(
    () => (!favorites && !favoritesError) || isLoading,
    [favorites, favoritesError, isLoading]
  );

  const { linkUtagAction } = useUtag();

  const addToFavorites = useCallback(
    (id: number, quantity?: number, options?: number[]) => {
      setIsLoading(true);
      mutateData("post", `${Endpoints.addToFavorites}/${id}`, {
        quantity,
        options,
      }).then(async () => {
        await mutate(Endpoints.favorites);
        await mutate(`${Endpoints.prize}/${id}`);
        linkUtagAction({
          event_category: "click",
          event_action: "button",
          event_value: `add_favourites||${id}`,
          cart_id: String(0),
          product_id: String(id),
          product_name: "",
          product_quantity: String(quantity),
        });
        setIsLoading(false);
      });
    },
    [linkUtagAction]
  );

  const deleteFromFavorites = useCallback(
    (prizeId: number) => {
      setIsLoading(true);
      mutateData("delete", `${Endpoints.removeFromFavorites}/${prizeId}`).then(
        async (res: any) => {
          await mutate(Endpoints.favorites);
          await mutate(Endpoints.cart);
          await mutate(`${Endpoints.prize}/${prizeId}`);
          linkUtagAction({
            event_category: "click",
            event_action: "button",
            event_value: `delete_favourites||${prizeId}`,
            cart_id: String(0),
            product_id: String(prizeId),
            product_name: "",
            product_quantity: String(0),
          });
          setIsLoading(false);
        }
      );
    },
    [linkUtagAction]
  );

  const moveToCart = useCallback(
    (id: number, title?: string, prizeId?: string) => {
      setIsLoading(true);
      mutateData("post", `${Endpoints.moveToCart}/${id}`).then(
        async (res: any) => {
          await mutateFavorites();
          await mutate(Endpoints.cart).then(({ prizes }) => {
            const foundItem = prizes.find(
              (cart: CartPrize) => cart.prize.id === Number(prizeId)
            );
            if (foundItem) {
              linkUtagAction({
                event_category: "cart",
                event_action: "creation",
                event_value: `creation||${foundItem.id}`,
                cart_id: String(foundItem.id),
                cart_quantity: foundItem.totals.itemQuantity,
                product_id: String(foundItem.prize.id),
                product_name: foundItem.prize.title,
                product_quantity: String(foundItem.totals.itemQuantity || 1),
              });
              linkUtagAction({
                event_category: "product",
                event_action: "add_to_cart",
                event_value: `add_to_cart||${id}`,
                cart_id: String(foundItem.id),
                product_id: String(foundItem.prize.id),
                product_name: foundItem.prize.title,
                product_quantity: String(foundItem.totals.itemQuantity),
              });
            }
          });
          setIsLoading(false);
        }
      );
    },
    [linkUtagAction, mutateFavorites]
  );

  return {
    favorites: favorites,
    favoritePrizes:
      favorites?.prizes && favorites?.prizes?.length > 0
        ? favorites?.prizes
        : null,
    favoritesError,
    loading,
    addToFavorites,
    deleteFromFavorites,
    moveToCart,
  };
};
