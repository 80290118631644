import {
  Paper,
  Typography,
  Input,
  Button,
  TextField,
  Box,
} from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EnvelopeIcon } from "../../../assets/icons/envelope.svg";
import { QuestionPropsType } from "../../../types/Components";
import { useStyles } from "./Question.styles";
import { validateEmail } from "../../../utils/Common";

const Question: React.FC<QuestionPropsType> = ({ onSent }) => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const styleProps = { formError };
  const classes = useStyles(styleProps);

  const { t } = useTranslation();

  const textareaHeight = useMemo(
    () => (window.innerHeight - 600 > 0 ? window.innerHeight - 600 : "unset"),
    []
  );

  const setError = useCallback(() => {
    setFormError(true);
    setTimeout(() => {
      setFormError(false);
    }, 3000);
  }, []);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (email === "" || message === "") {
        setError();
      }

      if (!!email && !validateEmail(email)) {
        setEmailError(true);
      }

      if (!!email && !!message && validateEmail(email)) {
        setEmailError(false);
        onSent({ email, message });
      }
    },
    [email, message, onSent, setError]
  );

  return (
    <Box className={classes.questionBox}>
      <Box className={classes.titleWrapper}>
        <Paper className={classes.envelope}>
          <EnvelopeIcon />
        </Paper>
        <Typography color="primary" className={classes.header}>
          {t("form.questions")}
        </Typography>
      </Box>
      <form onSubmit={submitHandler} className={classes.form} noValidate={true}>
        <Input
          placeholder={
            formError ? t("errors.empty_message") : t("form.your_email")
          }
          type="email"
          disableUnderline
          value={email}
          className={classes.email}
          error={emailError}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
            setEmailError(false);
          }}
        />
        {emailError && (
          <span className={classes.error_msg}>{t("form.pattern")}</span>
        )}
        <TextField
          placeholder={
            formError ? t("errors.empty_message") : t("form.type_question")
          }
          defaultValue={message}
          multiline
          margin="none"
          variant="outlined"
          rows={4}
          onChange={(e) => setMessage(e.currentTarget.value)}
          className={classes.text}
          style={{ maxHeight: textareaHeight }}
        />
        <Button variant="contained" className={classes.sendBtn} type="submit">
          {t("form.send")}
        </Button>
      </form>
    </Box>
  );
};

export default Question;
