import { Box, ListItem, MenuList } from "@material-ui/core";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { MobileNavMenuListTypes } from "../../../../types/Components";
import Loader from "../../../Loader/Loader";
import { useStyles } from "./MobileNavMenuList.styles";

const MobileNavMenuList: React.FC<MobileNavMenuListTypes> = ({
  closePopover,
  subroutes,
}) => {
  const classes = useStyles();

  const menuMobileSubroutes = useMemo(() => {
    if (!subroutes) return <Loader />;
    return subroutes?.map((subroute, i) =>
      subroute?.map(({ key, path }) => (
        <ListItem
          disableGutters={true}
          key={key}
          classes={{ root: classes.listItem }}
        >
          <NavLink
            to={path}
            activeClassName={classes.active}
            className={classes.link}
            onClick={closePopover}
            exact
          >
            {key}
          </NavLink>
        </ListItem>
      ))
    );
  }, [classes.active, classes.link, classes.listItem, closePopover, subroutes]);

  return (
    <Box className={classes.root}>
      <MenuList disablePadding>{menuMobileSubroutes}</MenuList>
    </Box>
  );
};
export default MobileNavMenuList;
