import { makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 144,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "3px",
      height: 40,
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px",
      lineHeight: "16px",
      letterSpacing: "0.03em",
      height: 16,
    },
    "& .MuiOutlinedInput-root > fieldset": {
      borderWidth: "1.2px",
      borderColor: Colors.middleGrey,
    },
  },
  mobileInput: {
    "& .MuiOutlinedInput-adornedEnd": {
      [theme.breakpoints.down("sm")]: {
        maxHeight: 28,
      },
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 94,
      width: "100%",
      "& .MuiOutlinedInput-input": {
        padding: "6px 12px",
      },
      "& .MuiOutlinedInput-root > fieldset": {
        borderWidth: "1px",
      },
    },
  },
  buttonUp: {
    paddingBottom: "1px",
    borderRadius: "unset",
  },
  buttonDown: {
    paddingTop: "1px",
    borderRadius: "unset",
  },
  arrowDown: {
    transform: "rotate(180deg)",
  },
}));
