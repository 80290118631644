import { useMediaQuery, useTheme } from "@material-ui/core";
import { useCallback } from "react";
import { BasfImage } from "../types/Common";

const useImageUrl = (image?: BasfImage | BasfImage[]) => {
  const getImageUrl = useGetImageUrl();

  if (Array.isArray(image)) {
    return image.map(getImageUrl);
  }

  return getImageUrl(image);
};

export const useGetImageUrl = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("xs"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  const isDownLg = useMediaQuery(theme.breakpoints.down("md"));

  const getImageUrl = useCallback(
    (image?: BasfImage): string | undefined => {
      if (isDownSm) {
        return image?.sm;
      }

      if (isDownMd) {
        return image?.md;
      }

      if (isDownLg) {
        return image?.lg;
      }

      return image?.xl;
    },
    [isDownLg, isDownMd, isDownSm]
  );

  return getImageUrl;
};

export default useImageUrl;
