import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "59px",
  },
  tableHeadCell: {
    padding: "0 21px 22px 21px",
  },
}));
