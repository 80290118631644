import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./TitleWithPoints.styles";
import { useTranslation } from "react-i18next";

interface IProps {
  accountPoints?: number | null;
  title: string;
}
export default function TitleWithPoints({ accountPoints, title }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Typography variant="h1" color="primary" className={classes.title}>
        {t(title)}
      </Typography>
      {!!accountPoints && (
        <Box display="flex" alignItems="baseline">
          <Typography variant="h4" className={classes.pointsTitle}>
            {`${t("common.points_on_my_account")}:`}
          </Typography>
          <Typography
            variant="h3"
            color="primary"
            className={classes.accountPoints}
          >
            {accountPoints}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
