import { Box, BoxProps, CircularProgress } from "@material-ui/core";

interface Props extends BoxProps {
  size?: number;
  circularProgressStyle?: React.CSSProperties;
}

export default function Loader({
  size,
  circularProgressStyle,
  ...rest
}: Props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...rest}>
      <CircularProgress size={size ?? 30} style={circularProgressStyle} />
    </Box>
  );
}
