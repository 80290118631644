import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "16px 0 !important",
    cursor: "pointer",
    "&:hover": {
      "& $link": {
        fontWeight: "bold",
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
    textAlign: "left",
    "&:hover": {
      textDecoration: "none",
    },
    "&::before": {
      display: "block",
      content: "attr(title)",
      fontWeight: "bold",
      height: 0,
      overflow: "hidden",
      visibility: "hidden",
    },
  },

  active: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  gridItem: {
    maxWidth: 260,
    "&:not(:last-child)": {
      paddingRight: "80px",
    },
    "& .MuiList-root": {
      outline: "none !important",
      border: "none !important",
    },
  },
}));
