import { useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { usePaths } from "../../../constants/routes";
import { useGetImageUrl } from "../../../hooks/useImageUrl";
import { useFavorites } from "../../../state/Favorites";
import { FavoritesListPropsType } from "../../../types/Components";
import { convertToSlug } from "../../../utils/Common";
import ListContent from "../Lists/ListContent/ListContent";
import ListItem from "../Lists/ListItem/ListItem";
import ListWrapper from "../Lists/ListWrapper/ListWrapper";
import NoResultView from "../NoResultView/NoResultView";

const FavoritesList: React.FC<FavoritesListPropsType> = ({
  translationKey,
  onClose,
}) => {
  const history = useHistory();
  const { favorites, deleteFromFavorites } = useFavorites();
  const paths = usePaths();
  const getImageUrl = useGetImageUrl();

  const onTitleClick = useCallback(() => {
    onClose();
    history.push(paths.favorites);
  }, [history, onClose, paths.favorites]);

  const onDeleteItem = useCallback(
    (id: number) => {
      deleteFromFavorites(id);
    },
    [deleteFromFavorites]
  );

  const onHandleOrder = useCallback(
    (path: string) => {
      history.push(path);
      onClose();
    },
    [history, onClose]
  );

  const isItems = useMemo(
    () => (!favorites?.prizes?.length ? true : false),
    [favorites?.prizes?.length]
  );

  const favoritesContent = useMemo(() => {
    if (!favorites?.prizes?.length)
      return (
        <NoResultView
          path={paths.prizes.replace("/:category?", "")}
          title="check_all_prizes"
          info={"no_favorites"}
        />
      );

    const favoritesMap = favorites?.prizes?.map((prize) => {
      const sanitizeTitle =
        prize?.prize.slugTitle || convertToSlug(prize.prize.title);
      const pathname = paths.prizeItem
        .replace(":prizeName", sanitizeTitle)
        .replace(":prizeId", `${prize.prize.id}`);
      return (
        <Link to={pathname} key={`header-favorite-${prize.id}`}>
          <ListItem
            imageUrl={getImageUrl(prize.prize.images?.[0]) as string}
            title={prize.prize.title}
            points={prize.prize.unitPoints}
            itemId={prize.prize.id}
            onDelete={onDeleteItem}
            isScroll={favorites.prizes.length}
            subtitle={prize.prize.subTitle}
          />
        </Link>
      );
    });
    return (
      <ListContent
        points={favorites?.totals?.amountInPoints}
        buttonName={"buttons.view_all"}
        onClick={() => onHandleOrder(paths.favorites)}
        isScroll={favorites?.prizes?.length}
      >
        {favoritesMap}
      </ListContent>
    );
  }, [
    favorites.prizes,
    favorites?.totals?.amountInPoints,
    getImageUrl,
    onDeleteItem,
    onHandleOrder,
    paths.favorites,
    paths.prizeItem,
    paths.prizes,
  ]);

  return (
    <ListWrapper
      translationKey={translationKey}
      onClick={onTitleClick}
      isItems={isItems}
    >
      {favoritesContent}
    </ListWrapper>
  );
};
export default FavoritesList;
