import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

interface IProps {
  isScroll: number;
  innerHeight: number;
}

export const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    list: {
      overflowY: "auto",
      maxHeight: ({ innerHeight }) => (innerHeight > 800 ? 383 : 250),
      scrollbarWidth: ({ isScroll }) => (isScroll < 3 ? "none" : "thin"),
      scrollbarColor: "#d6dee1 transparent",
      "-ms-overflow-style": ({ isScroll }) => (isScroll < 3 ? "none" : "auto"),
      "&::-webkit-scrollbar": {
        display: ({ isScroll }) => (isScroll < 3 ? "none" : "block"),
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#d6dee1",
        borderRadius: "20px",
        border: "6px solid transparent",
        "&::hover": {
          backgroundColor: "a8bbbf",
        },
      },
      [theme.breakpoints.down("md")]: {
        scrollbarWidth: ({ isScroll }) => (isScroll < 2 ? "none" : "thin"),
        "-ms-overflow-style": ({ isScroll }) =>
          isScroll < 2 ? "none" : "auto",
        "&::-webkit-scrollbar": {
          display: ({ isScroll }) => (isScroll < 2 ? "none" : "block"),
        },
        maxHeight: "263px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "12px",
        maxHeight: ({ innerHeight }) => (innerHeight > 687 ? 263 : 140),
      },
    },

    pointsWrap: {
      display: "flex",
      justifyContent: "space-between",
      padding: "24px 0 20px 0",
      [theme.breakpoints.down("xs")]: {
        padding: ({ innerHeight }) =>
          innerHeight > 687 ? "24px 0 33px 0" : "12px 0 12px 0",
      },
    },
    subtitle: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        lineHeight: "29px",
      },
    },
    btn: {
      width: "100%",
      height: ({ innerHeight }) => (innerHeight > 687 ? 48 : 40),
      background: theme.palette.primary.main,
      fontSize: "16px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "8px 16px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  })
);
