import { makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "../../../constants/Style";

export const useStyles = makeStyles((theme: Theme) => ({
  points: {
    lineHeight: "44px",
  },
  tableRow: {
    height: 1,
    "& .MuiTableCell-body": {
      height: "inherit",
      padding: "32px 109px 32px 16px",
      verticalAlign: "top",
      [theme.breakpoints.down("md")]: {
        paddingRight: "32px",
      },
      "&:last-child": {
        paddingRight: "30px",
      },
    },
  },
  actionButton: {
    padding: 0,
    "& .MuiButton-label": {
      borderBottom: `1px solid ${Colors.darkBlue}`,
    },
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "204px",
  },
  title: {
    fontWeight: 500,
    width: "304px",
  },
  image: {
    height: 204,
    width: 217,
    backgroundSize: "contain",
    marginLeft: "auto",
  },
  buttonContainer: {
    marginTop: "auto",
    paddingBottom: "14px",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    height: "19px",
    backgroundColor: Colors.darkBlue,
    margin: "0 18px",
  },
  addToCartBtn: {
    height: 48,
    fontWeight: "bold",
    padding: "10px 32px 10px 24px",
    minWidth: 230,
    "& svg": {
      width: 28,
      height: 28,
      "& path": {
        fill: Colors.white,
      },
    },
    "& .MuiButton-startIcon": {
      marginRight: "32px",
    },
  },
  attributeOption: {
    fontSize: 14,
  },
}));
