import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./NavPopperTitle.styles";
import clsx from "clsx";

interface NavPopperTitlePropsType {
  translationKey: string;
  disable?: boolean;
  onClick?: () => void;
}

const NavPopperTitle: React.FC<NavPopperTitlePropsType> = ({
  translationKey,
  onClick,
  disable,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        fullWidth
        className={clsx(classes.PopupHeaderText, {
          [classes.noTouch]: !onClick,
        })}
        onClick={onClick}
        disabled={disable}
      >
        <Typography variant="body1" className={classes.PopupText}>
          {t(`menu.${translationKey}`)}
        </Typography>
      </Button>
    </Box>
  );
};
export default NavPopperTitle;
