import jwtDecode from "jwt-decode";
import getUnixTime from "date-fns/getUnixTime";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { instance, mutateData, setAuthHeader } from "../api/api";
import { Endpoints } from "../api/endpoints";
import { usePaths } from "../constants/routes";
import { useLoading } from "../hooks/useLoading";
import {
  AuthData,
  AuthToken,
  LoginRequest,
  LoginResponse,
  RemindPasswordRequest,
  ResetPasswordRequest,
  SignUpRequest,
  SignUpDistributorRequest,
  UseAuth,
} from "../types/Auth";
import { useUtag } from "./Utag";
import { storeData, removeData } from "../utils/LocalStorage";

const initialAuthValues = {
  isLoggedIn: false,
  signup: (data: SignUpRequest) => {},
  logout: () => {},
  loading: false,
};

const authContext = createContext(initialAuthValues);

const useProvideAuth = (): UseAuth => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const { loading, startLoading, stopLoading } = useLoading();
  const paths = usePaths();
  const { clearUtag } = useUtag();

  const storedToken = useMemo(() => {
    const localStorageToken = localStorage.getItem("basf-auth-token");
    const sessionStorageToken = sessionStorage.getItem("basf-auth-token");

    if (!!sessionStorageToken) return sessionStorageToken;
    if (!!localStorageToken) return localStorageToken;
    return null;
  }, []);
  const login = useCallback(
    async (data: LoginRequest) => {
      startLoading();
      const resData: AuthData = await mutateData("post", Endpoints.login, data)
        .then((res) => {
          if (!!res.data?.token) {
            setToken(res.data.token);
            setAuthHeader(res.data.token);
            setIsLoggedIn(true);

            // if logged in user needs update, redirect to profile page
            if (res?.data?.isNeedUpdate) {
              storeData(res.data.isNeedUpdate, "BASF-isNeedUpdate");
              history.push(paths.profile);
            }

            return {
              success: true,
              data: res.data as LoginResponse,
            };
          }
          const resetToken = res.data?.resetToken;
          if (!!resetToken) {
            history.push(`/auth/create-password?token=${resetToken}`);
            return {
              success: true,
              data: res.data as LoginResponse,
            };
          }
          return {
            success: false,
            data: res.data as LoginResponse,
            message: "Token missing",
          };
        })
        .catch((e) => {
          const errorData = e?.response?.data;
          return {
            success: false,
            data: errorData,
          };
        })
        .finally(() => stopLoading());
      return resData;
    },
    [history, startLoading, stopLoading, paths.profile]
  );

  const signup = useCallback(
    async (data: SignUpRequest) => {
      startLoading();
      delete (data as any)?.userCrops;
      const resData = await mutateData("post", Endpoints.signup, data)
        .then((res) => {
          return { success: true };
        })
        .catch((e) => {
          return e?.response?.data;
        })
        .finally(() => stopLoading());

      return resData;
    },
    [startLoading, stopLoading]
  );

  const signupDistributor = useCallback(
    async (data: SignUpDistributorRequest) => {
      startLoading();

      delete (data as any)?.first;
      delete (data as any)?.second;

      const resData = await mutateData(
        "post",
        Endpoints.signupDistributor,
        data
      )
        .then((res) => {
          return { success: true };
        })
        .catch((e) => {
          return e?.response?.data;
        })
        .finally(() => stopLoading());

      return resData;
    },
    [startLoading, stopLoading]
  );

  const activateDistributor = useCallback(
    async (token: string) => {
      startLoading();
      const resData = await mutateData(
        "get",
        `${Endpoints.activateDistributor}/${token}`
      )
        .then((res) => {
          return {
            success: true,
            status: res.status,
          };
        })
        .catch((e) => {
          const currentStatus = e?.response?.status;
          return {
            success: false,
            status: currentStatus,
          };
        })
        .finally(() => stopLoading());

      return resData;
    },
    [startLoading, stopLoading]
  );

  const logout = useCallback(() => {
    sessionStorage.removeItem("basf-auth-token");
    localStorage.removeItem("basf-auth-token");
    localStorage.removeItem("basf-remember-me");
    localStorage.removeItem("BASF-chosenCategories");
    setToken("");
    removeData("BASF-isNeedUpdate");
    setAuthHeader("");
    setIsLoggedIn(false);
    clearUtag();
    if (
      location.pathname !== paths.login &&
      location.pathname !== paths.landing
    )
      setTimeout(() => history.push(paths.login), 500);
  }, [clearUtag, history, location.pathname, paths.landing, paths.login]);

  const resetPassword = useCallback(
    async (data: ResetPasswordRequest, resetToken: string) => {
      startLoading();
      const resData = await mutateData(
        "post",
        `${Endpoints.resetPassword}/${resetToken}`,
        data
      )
        .then((res) => {
          return {
            success: true,
            status: res.status,
          };
        })
        .catch((e) => {
          const currentStatus = e?.response?.status;
          return {
            success: false,
            message: t("errors.server_error"),
            status: currentStatus,
            data: e?.response?.data,
          };
        })
        .finally(() => stopLoading());

      return resData;
    },
    [startLoading, stopLoading, t]
  );

  const checkResetToken = useCallback(
    async (resetToken: string) => {
      startLoading();
      const resData = await mutateData(
        "post",
        `${Endpoints.confirmResetToken}/${resetToken}`
      )
        .then((res) => {
          return {
            success: true,
            status: res.status,
          };
        })
        .catch((e) => {
          const currentStatus = e?.response?.status;
          return {
            success: false,
            status: currentStatus,
          };
        })
        .finally(() => stopLoading());

      return resData;
    },
    [startLoading, stopLoading]
  );

  const remindPassword = useCallback(
    async ({ email }: RemindPasswordRequest) => {
      startLoading();
      const resData = await mutateData(
        "get",
        `${Endpoints.remindPassword}/${email}`
      )
        .then((res) => {
          return {
            success: true,
            status: res.status,
          };
        })
        .catch((e) => {
          const currentStatus = e?.response?.status;
          return {
            success: false,
            status: currentStatus,
          };
        })
        .finally(() => stopLoading());

      return resData;
    },
    [startLoading, stopLoading]
  );

  const checkIfTokenExpired = useCallback((token: string | null) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token) as AuthToken;
    const tokenExpiration = decodedToken.exp;
    const currentTimeStamp = getUnixTime(new Date());
    return tokenExpiration < currentTimeStamp - 5;
  }, []);

  const getTokenExpirationTimeout = useCallback((token: string | null) => {
    if (!token) return 0;
    const decodedToken = jwtDecode(token) as AuthToken;
    const tokenExpiration = decodedToken.exp;
    const currentTimeStamp = getUnixTime(new Date());
    return (tokenExpiration - currentTimeStamp - 5) * 1000;
  }, []);

  useEffect(() => {
    startLoading();
    if (!token) {
      if (!!storedToken) {
        const isExpired = checkIfTokenExpired(storedToken);
        if (!isExpired) {
          setToken(storedToken);
          setAuthHeader(storedToken);
          setIsLoggedIn(true);
        }
      }
    }

    setTimeout(() => stopLoading(), 500);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const localStorageToken = localStorage.getItem("basf-auth-token");
    const sessionStorageToken = sessionStorage.getItem("basf-auth-token");
    const storedToken = !!sessionStorageToken
      ? sessionStorageToken
      : localStorageToken;

    const tokenForExpiration = !!token ? token : storedToken;
    if (!!tokenForExpiration) {
      const timeout = getTokenExpirationTimeout(tokenForExpiration);
      if (timeout > 0) {
        setTimeout(() => {
          logout();
        }, timeout);
      } else {
        logout();
      }
    }
  }, [getTokenExpirationTimeout, logout, token]);

  instance.interceptors.request.use(
    async (config) => {
      const headerToken = config?.headers?.common?.["Authorization"];
      const parsedHeaderToken = headerToken?.replace("Bearer ", "");
      if (!!parsedHeaderToken) {
        const isExpired = checkIfTokenExpired(parsedHeaderToken);
        if (isExpired) {
          logout();
        }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return {
    isLoggedIn,
    login,
    signup,
    signupDistributor,
    loading,
    logout,
    activateDistributor,
    resetPassword,
    checkResetToken,
    remindPassword,
    token,
    storedToken,
  };
};

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): UseAuth => {
  return useContext(authContext);
};
