import { useCallback, useState } from "react";
import { Endpoints } from "../api/endpoints";
import { mutateData } from "../api/api";
import { ContactUsFormType } from "../types/Components";
import { UseContacts } from "../types/Contacts";

export const useContacts = (): UseContacts => {
  const [loading, setLoading] = useState<boolean>(false);

  const sendQuestion = useCallback(async (data: ContactUsFormType) => {
    setLoading(true);
    return await mutateData("post", Endpoints.sendQuestion, data)
      .then((res: any) => {
        return res.status === 204;
      })
      .catch((err) => {
        return false;
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    sendQuestion,
    loading,
  };
};
