import { Box, Hidden, Divider } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { ListWrapperPropsTypes } from "../../../../types/Components";
import NavPopperTitle from "../../../NavPopper/NavPopperTitle/NavPopperTitle";
import { useStyles } from "./ListWrapper.styles";

const ListWrapper: React.FC<ListWrapperPropsTypes> = ({
  children,
  onClick,
  translationKey,
  isItems,
}) => {
  const innerHeight = useMemo(() => window.innerHeight, []);
  const classes = useStyles(innerHeight);

  const isItemsClasses = useMemo(() => {
    return clsx({
      [classes.root]: true,
      [`${classes.root} ${classes.noItems}`]: isItems === true,
    });
  }, [classes.noItems, classes.root, isItems]);

  return (
    <Box className={isItemsClasses}>
      <Hidden xsDown>
        <Divider className={classes.divider} />
      </Hidden>
      <NavPopperTitle
        translationKey={translationKey}
        onClick={onClick}
        disable
      />
      <Hidden xsDown>
        <Divider className={classes.divider} />
      </Hidden>
      {children}
    </Box>
  );
};
export default ListWrapper;
