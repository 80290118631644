import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const usePointsTranslation = (points: number): string => {
  const { t } = useTranslation();
  const pointString = points.toString();
  const lastChar = pointString.slice(-1);

  if (
    lastChar === "0" ||
    (points < 21 && points > 10) ||
    (points % 100 < 21 && points % 100 > 10)
  ) {
    return t("common.points_100");
  }

  if (lastChar === "1") {
    return t("common.points_1", {
      count: 1,
    });
  }

  return t("common.points");
};

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();

  const isLT = useMemo(() => i18n?.language === "lt", [i18n.language]);

  const isLV = useMemo(() => i18n?.language === "lv", [i18n.language]);

  const isEE = useMemo(() => i18n?.language === "et", [i18n.language]);

  return {
    isLT,
    isLV,
    isEE,
  };
};

export const useDaysTranslation = (days: number): string => {
  const { t } = useTranslation();
  const { isEE } = useCurrentLanguage();

  if (days === 1 || days === 21 || days === 31) {
    if ((days === 21 || days === 31) && isEE) {
      return t("common.days_alt");
    }
    return t("common.days_single");
  }

  return t("common.days_alt");
};
