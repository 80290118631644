import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100vw",
      position: "relative",
      marginLeft: "-50vw",
      left: "50%",
      height: "100%",
    },
    topBorder: {
      [theme.breakpoints.up("md")]: {
        borderTop: `1px solid ${Colors.boxGrey}`,
      },
    },
  })
);
