import { useEffect } from "react";
import { withRouter, matchPath } from "react-router-dom";
import { History } from "history";
import { usePaths } from "../constants/routes";

function ScrollToTop({ history }: { history: History }) {
  const paths = usePaths();
  useEffect(() => {
    const unlisten = history.listen(() => {
      // add scroll exception to prizes pathname
      const isMatched = !!matchPath(history.location.pathname, paths.prizes);
      if (!isMatched) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
