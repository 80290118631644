import {
  Box,
  Dialog,
  Grow,
  IconButton,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { ReactComponent as ChatIcon } from "../../assets/icons/chatIcon.svg";
import { ReactComponent as CloseDialogIcon } from "../../assets/icons/closeDialog.svg";
import { useContacts } from "../../state/Contact";
import { ContactUsFormType } from "../../types/Components";
import { useStyles } from "./BannetForm.styles";
import Question from "./Question/Question";
import QuestionSent from "./QuestionSent/QuestionSent";
import Loader from "../Loader/Loader";

const BannerForm = () => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSent, setIsSent] = useState<boolean>(false);
  const { sendQuestion, loading } = useContacts();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onSent = useCallback(
    async (data: ContactUsFormType) => {
      const res = await sendQuestion(data);
      if (res) {
        setIsSent(true);
        setTimeout(() => {
          setOpen(false);
          setAnchorEl(null);
        }, 1500);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    [sendQuestion]
  );

  const onOpenHandler = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setOpen(!open);
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setIsSent(false);
    },
    [anchorEl, open]
  );

  const Content = useMemo(() => {
    if (loading) return <Loader flexGrow={1} />;
    if (isSent) return <QuestionSent />;
    return <Question onSent={onSent} />;
  }, [loading, isSent, onSent]);

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      {isMobile ? (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          classes={{
            paper: classes.dialogPaper,
            container: classes.dialogContainer,
          }}
          fullScreen
          disableBackdropClick
        >
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIconButton}
            color="primary"
          >
            <CloseDialogIcon />
          </IconButton>
          <Paper elevation={0} className={classes.paperMobile}>
            <Box className={classes.formContent}>
              <Paper className={classes.questionForm} elevation={0}>
                {Content}
              </Paper>
            </Box>
          </Paper>
        </Dialog>
      ) : (
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          placement="top-end"
          modifiers={{
            flip: {
              enabled: false,
            },
          }}
          className={classes.popperMobile}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              timeout={300}
              in={open}
              style={{ transformOrigin: "bottom" }}
            >
              <Paper elevation={isSm ? 3 : 1}>
                <Box className={classes.formContent}>
                  <Paper className={classes.questionForm} elevation={0}>
                    {Content}
                  </Paper>
                </Box>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}

      <Box className={classes.root}>
        <IconButton className={classes.btn} onClick={onOpenHandler}>
          {!open ? <ChatIcon /> : <CloseDialogIcon />}
        </IconButton>
      </Box>
    </>
  );
};
export default BannerForm;
