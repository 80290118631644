import { Helmet } from "react-helmet";

export default function MetaHead() {
  const indexVariable = process?.env.REACT_APP_SHOULD_INDEX;

  const shouldIndex = indexVariable === "true";

  return (
    <Helmet>
      {shouldIndex ? (
        <meta name="robots" content="all" />
      ) : (
        <meta name="robots" content="none" />
      )}
    </Helmet>
  );
}
