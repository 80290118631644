import { useMemo } from "react";
import { useCategories } from "../state/Products";
import { Category } from "../types/Products";
import { Link } from "../types/Common";
import { useFilters } from "../state/Prizes";
import { usePaths } from "../constants/routes";
import { useProfileBriefInfo } from "../state/Profile";
import { useTranslation } from "react-i18next";

export default function useLinksUtil() {
  const { categories } = useCategories();
  const { prizeCategories } = useFilters();
  const { isBasfUser } = useProfileBriefInfo();
  const paths = usePaths();
  const { t } = useTranslation();

  /** PRODUCT CATEGORIES **/
  const productsLinks = useMemo(
    () =>
      (categories as Category[])?.map((cat) => {
        return {
          path: paths.products.replace(":category?", cat.id),
          key: cat.title,
        };
      }),
    [categories, paths.products]
  );
  const productsLinksLength = useMemo(
    () => productsLinks?.length,
    [productsLinks]
  );
  const productLinksColumns = useMemo(
    () => [
      productsLinks?.slice(0, Math.ceil(productsLinksLength / 2)),
      productsLinks?.slice(Math.ceil(productsLinksLength / 2)),
    ],
    [productsLinks, productsLinksLength]
  );

  /** PRIZE CATEGORIES **/
  const prizeCategoriesLinks = useMemo(() => {
    const links = (prizeCategories as Category[])?.map((cat) => {
      return {
        path: paths.prizes.replace(":category?", cat.id),
        key: cat.title,
      };
    });
    // adding all prizes as first child menu link
    return links?.length
      ? [
          {
            path: paths.prizes.replace("/:category?", ""),
            key: t("titles.all_prizes"),
          },
          ...links,
        ]
      : [];
  }, [paths.prizes, prizeCategories, t]);
  const prizeCategoriesLinksLength = useMemo(
    () => prizeCategoriesLinks?.length,
    [prizeCategoriesLinks]
  );
  const prizeCategoriesLinksColumns = useMemo(
    () => [
      prizeCategoriesLinks?.slice(0, Math.ceil(prizeCategoriesLinksLength / 2)),
      prizeCategoriesLinks?.slice(Math.ceil(prizeCategoriesLinksLength / 2)),
    ],
    [prizeCategoriesLinks, prizeCategoriesLinksLength]
  );

  const navLinks = useMemo(
    (): Link[] => [
      {
        key: "dashboard",
        path: paths.home,
      },
      {
        key: "prizes",
        path: paths.prizes.replace("/:category?", ""),
        children: prizeCategoriesLinksColumns,
      },
      {
        key: "products",
        path: paths.products.replace("/:category?", ""),
        children: productLinksColumns,
      },
    ],
    [
      paths.prizes,
      paths.products,
      paths.home,
      prizeCategoriesLinksColumns,
      productLinksColumns,
    ]
  );

  const distributorNavLinks = useMemo(
    (): Link[] => [
      {
        key: "dashboard",
        path: paths.home,
      },
      {
        key: "prizes",
        path: paths.prizes.replace("/:category?", ""),
        children: prizeCategoriesLinksColumns,
      },
    ],
    [paths.prizes, paths.home, prizeCategoriesLinksColumns]
  );

  return {
    productsLinks,
    navLinks: (isBasfUser ? navLinks : distributorNavLinks) ?? [],
  };
}

export const useProfileNavLinks = (): Array<{
  key: string;
  path: string;
  action?: string;
}> => {
  const paths = usePaths();
  const { isBasfUser } = useProfileBriefInfo();

  const basfUserProfileLinks = useMemo(
    () => [
      { key: "header.my_profile", path: paths.profile },
      { key: "common.my_invoices", path: paths.myInvoices },
      { key: "common.my_orders", path: paths.myOrders },
      { key: "common.my_points_history", path: paths.myPointsHistory },
      { key: "header.change_password", path: paths.changePassword },
      { key: "header.logout", path: paths.login, action: "logout" },
    ],
    [
      paths.changePassword,
      paths.login,
      paths.myInvoices,
      paths.myOrders,
      paths.myPointsHistory,
      paths.profile,
    ]
  );

  const distributorProfileLinks = useMemo(
    () => [
      { key: "header.my_profile", path: paths.profile },
      { key: "common.my_orders", path: paths.myOrders },
      { key: "common.my_points_history", path: paths.myPointsHistory },
      { key: "header.change_password", path: paths.changePassword },
      { key: "header.logout", path: paths.login, action: "logout" },
    ],
    [
      paths.changePassword,
      paths.login,
      paths.myOrders,
      paths.myPointsHistory,
      paths.profile,
    ]
  );

  return isBasfUser ? basfUserProfileLinks : distributorProfileLinks;
};
