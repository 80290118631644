import { PropsWithChildren, useCallback, useMemo } from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  PopperProps,
  Slide,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "./HeaderPopper.styles";

export default function HeaderPopper({
  open,
  anchorEl,
  children,
  onBackdropClick,
  zIndex,
  ...rest
}: PopperProps & PropsWithChildren<any>) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const popperWidth = useMemo(
    () => (isMobile ? window.innerWidth : "100%"),
    [isMobile]
  );

  const onClickAway = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onBackdropClick();
    },
    [onBackdropClick]
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      {...rest}
      className={classes.root}
      placement="bottom-end"
      style={{
        zIndex: zIndex,
      }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClickAway}>
          <Slide {...TransitionProps} timeout={250} in={open}>
            <Paper elevation={1}>
              <Box width={popperWidth}>{children}</Box>
            </Paper>
          </Slide>
        </ClickAwayListener>
      )}
    </Popper>
  );
}
