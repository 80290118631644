import { Box, Button, Typography } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as HeaderCartIcon } from "../../../assets/icons/popperCart.svg";
import { NoResultViewPropType } from "../../../types/Components";
import { useStyles } from "./NoResultView.styles";

const NoResultView: React.FC<NoResultViewPropType> = ({
  path,
  title,
  info,
}) => {
  const innerHeight = useMemo(() => window.innerHeight, []);
  const classes = useStyles(innerHeight);
  const { t } = useTranslation();
  const history = useHistory();

  const parsedTitle = useMemo(() => {
    const translatedTitle = t(`prizes.${title}`);

    return (
      translatedTitle.charAt(0).toUpperCase() +
      translatedTitle.slice(1).toLowerCase()
    );
  }, [t, title]);

  const onHandleRedirect = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );
  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        <HeaderCartIcon />
      </Box>
      <Typography variant="subtitle2" className={classes.noResults}>
        {t("errors.no_results")}
      </Typography>
      <Typography variant="subtitle2">{t(`errors.${info}`)}</Typography>
      <Button
        color="primary"
        className={classes.button}
        onClick={() => onHandleRedirect(path)}
        disableElevation
        variant="contained"
        fullWidth
      >
        {parsedTitle}
      </Button>
    </Box>
  );
};
export default NoResultView;
