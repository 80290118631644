import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";
export const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    envelope: {
      borderRadius: "100%",
      width: "42px",
      height: "42px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(1),
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    },
    header: {
      fontSize: "16px",
      lineHeight: "25px",
      marginBottom: "12px",
      fontWeight: 700,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        marginTop: 10,
      },
    },
    email: {
      border: `1px solid ${Colors.boxGrey}`,
      padding: "8px 0 7px 12px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "25.2px",
      width: "100%",
      marginBottom: theme.spacing(1),
      height: 50,
      "& input": {
        "&::placeholder": {
          color: ({ formError }) =>
            formError ? Colors.basfRed : Colors.darkGrey,
        },
      },
      "&.Mui-error": {
        border: `1px solid ${Colors.basfRed}`,
        marginBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        height: 30,
        fontSize: 12,
        lineHeight: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "initial",
        fontSize: "initial",
        lineHeight: "initial",
      },
    },
    text: {
      width: "100%",
      marginBottom: "15px",
      minHeight: 100,
      "& div": {
        padding: "8px 12px",
        height: "100%",
        minHeight: "100px",
        display: "flex",
        alignItems: "flex-start",
        border: `1px solid ${Colors.boxGrey}`,
        "& textarea": {
          resize: "none",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "25.2px",
          height: "100%",
          "&::placeholder": {
            color: ({ formError }) =>
              formError ? Colors.basfRed : Colors.darkGrey,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            lineHeight: "16px",
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: "initial",
            lineHeight: "initial",
          },
        },
        "& fieldset": {
          border: "none",
          padding: 0,
          margin: 0,
        },
      },
      [theme.breakpoints.up("md")]: {
        height: 200,
      },
      [theme.breakpoints.up("lg")]: {
        height: 300,
      },
      [theme.breakpoints.down("xs")]: {
        flex: 1,
        maxHeight: "none !important",
        transition: "all 0.5s ease-in-out",
        "& textarea": {
          resize: "none",
          fontSize: "14px",
        },
      },
    },
    sendBtn: {
      width: "100%",
      height: "48px",
      [theme.breakpoints.down("sm")]: {
        height: 40,
        fontSize: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "initial",
        fontSize: "initial",
      },
    },
    form: {
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    error_msg: {
      color: Colors.basfRed,
      marginBottom: 8,
      display: "inline-block",
    },

    questionBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        width: "100%",
      },
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        marginBottom: 10,
        marginTop: 10,
      },
    },
  })
);
