import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: "32px 45px",
    width: 542,
    [theme.breakpoints.down("sm")]: {
      padding: "32px",
    },
  },
  title: {
    padding: "0 0 12px 0",
  },
  content: {
    padding: "12px 0 0 0",
  },
  action: {
    padding: "55px 0 0 0",
  },
  closeButton: {
    margin: "-12px",
  },
  description: {
    maxWidth: "70%",
    marginTop: "12px",
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: "16px",
    },
  },
  descriptionLarge: {
    maxWidth: "100%",
    fontSize: "32px",
    lineHeight: "36px",
    marginTop: "20px",
    marginBottom: "20px",
    "& a": {
      fontSize: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      marginTop: 20,
      marginBottom: 0,
      lineHeight: "28px",
      "& a": {
        fontSize: "24px",
      },
    },
  },
}));
