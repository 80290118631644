import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "32px 0 91px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    icon: {
      width: "50px",
      height: "50px",
      background: theme.palette.primary.main,
      marginBottom: "24px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontWeight: 700,
      fontSize: theme.spacing(2),
      lineHeight: "20px",
      color: theme.palette.primary.main,
      textAlign: "center",
    },
  })
);
