import { useState, useCallback } from "react";
import { Endpoints } from "../api/endpoints";
import { mutateData } from "../api/api";
import { useLoading } from "../hooks/useLoading";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";

export const useCoupon = () => {
  const { t } = useTranslation();
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");

  const { loading, startLoading, stopLoading } = useLoading();

  const clearCoupon = useCallback(() => {
    setCouponCode("");
    setCouponError("");
  }, []);

  const applyCoupon = useCallback(
    (code: string) => {
      clearCoupon();
      setCouponError("");
      startLoading();
      return mutateData("post", `${Endpoints.redeemCoupon}`, { voucher: code })
        .then((res: any) => {
          stopLoading();
          setCouponCode(res.status === 204 ? code : "");
          mutate(Endpoints.profileBriefInfo);
        })
        .catch((err) => {
          const formErrors = err?.response?.data?.formErrors;
          const errors = err?.response?.data?.error;

          stopLoading();

          if (err?.response?.status === 404) {
            setCouponError(t("errors.no_voucher_found"));
            return;
          }

          if (
            !!formErrors &&
            Array.isArray(formErrors) &&
            formErrors.length > 0
          ) {
            setCouponError(formErrors[0].message);
          } else if (errors) {
            setCouponError(
              err?.response?.data?.error?.message || err?.message || ""
            );
          } else {
            setCouponError("");
          }
        });
    },
    [clearCoupon, startLoading, stopLoading, t]
  );

  return {
    applyCoupon,
    couponError,
    loadingCoupon: loading,
    appliedCoupon: couponCode,
    clearCoupon,
  };
};
