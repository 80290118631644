import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
    textAlign: "left",
  },

  active: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  listItem: {
    paddingTop: 14,
    paddingBottom: 14,
  },
}));
