import { createTheme, ThemeOptions } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export default createTheme({
  palette: {
    primary: {
      main: Colors.darkBlue,
    },
    error: {
      main: Colors.basfRed,
    },
    background: {
      default: Colors.white,
    },
    secondary: {
      main: Colors.paleDarkBlue,
      contrastText: Colors.darkBlue,
    },
    text: {
      primary: Colors.copyTextGrey,
      secondary: Colors.middleGrey,
    },
  },
  typography: {
    fontFamily: "Helvetica Neue LT W05_45 Light",
    h1: {
      fontFamily: "Helvetica Neue LT W05_75 Bold",
      fontSize: "40px",
      lineHeight: "54px",
    },
    h2: {
      fontFamily: "Helvetica Neue LT W05_75 Bold",
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "0.02em",
    },
    h3: {
      fontFamily: "Helvetica Neue LT W05_65 Medium",
      fontSize: "24px",
      lineHeight: "29px",
    },
    h4: {
      fontFamily: "Helvetica Neue LT W05_65 Medium",
      fontSize: "20px",
      lineHeight: "36px",
    },
    h5: {
      fontFamily: "Helvetica Neue LT W05_65 Medium",
      fontSize: "18px",
      lineHeight: "32px",
    },
    h6: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      fontFamily: "Helvetica Neue LT W05_65 Medium",
    },
    body1: {
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "29px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "25.2px",
    },
    button: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "16px",
      textTransform: "inherit",
      letterSpacing: "0.03em",
    },
    subtitle1: {
      fontSize: "13px",
      lineHeight: "30px",
      fontFamily: "Helvetica Neue LT W05_65 Medium",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "14.4px",
      fontWeight: 400,
      color: "#7C7C7C",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "16px 32px",
        borderRadius: "none",
      },
      contained: {
        backgroundColor: Colors.darkBlue,
        color: Colors.white,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: Colors.basfBlue,
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: Colors.basfBlue,
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: Colors.darkBlue,
          color: Colors.white,
        },
      },
      outlined: {
        color: Colors.darkBlue,
        borderColor: Colors.darkBlue,
        padding: "16px 32px",
      },
      outlinedPrimary: {
        color: Colors.darkBlue,
        borderColor: Colors.darkBlue,
        backgroundColor: Colors.white,
        "&:hover": {
          backgroundColor: Colors.darkBlue,
          color: Colors.white,
        },
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "unset",
          color: Colors.basfBlue,
        },
      },
    },
    MuiTextField: {
      root: {
        "& input": {
          height: 28,
          padding: "11px 16px",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: Colors.lightGrey,
          },
          "&:hover fieldset": {
            borderColor: Colors.lightGrey,
          },
          "&.Mui-focused fieldset": {
            borderColor: Colors.darkBlue,
            borderWidth: "1px",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.basfRed,
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          color: Colors.basfRed,
          fontSize: "10px",
          lineHeight: "16px",
          fontWeight: 400,
          whiteSpace: "nowrap",
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "unset",
      },
      input: {
        padding: "16px 15px",
        fontSize: "16px",
        color: Colors.copyTextGrey,
        "&::placeholder": {
          color: "#000000",
        },
      },
      inputMarginDense: {
        paddingTop: "16px",
        paddingBottom: "16px",
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "unset",
          textDecoration: "underline",
        },
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    MuiMenu: {
      list: {
        paddingTop: "8px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        minWidth: "253px",
      },
    },
    MuiPaper: {
      root: {
        "& .MuiList-root": {
          color: Colors.darkBlue,
          "& .MuiSvgIcon-colorAction": {
            color: "unset",
          },
        },
        "& .MuiListItem-button": {
          "&:hover": {
            textDecoration: "unset",
          },
          "&:focus": {
            "& .MuiTypography-subtitle2": {
              fontWeight: 700,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: "16px",
        paddingBottom: "16px",
        lineHeight: "16px",
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: "unset",
        borderBottom: `3px solid ${Colors.darkBlue}`,
        borderRadius: "unset",
        "& .MuiButtonBase-root": {
          color: Colors.copyTextGrey,
          "&:hover": {
            color: Colors.darkBlue,
            fontWeight: 700,
            textDecoration: "unset",
          },
        },
      },
      root: {
        "& .MuiListItem-root.Mui-selected,": {
          backgroundColor: "unset",
        },
      },
    },
    MuiToolbar: {
      root: {
        height: "79px",
      },
      gutters: {
        "@media (max-width: 600px)": {
          paddingLeft: 18,
          paddingRight: 18,
        },
      },
    },
    MuiBadge: {
      badge: {
        padding: 0,
        height: 16,
        minWidth: 16,
        fontSize: 12,
      },
    },
    MuiTableRow: {
      root: {
        "&.MuiTableRow-hover": {
          "&:hover": {
            backgroundColor: Colors.boxGrey,
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${Colors.boxGrey}`,
      },
    },
  },
}) as ThemeOptions;
