import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingLeft: 8,
      marginLeft: 12,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        marginLeft: 14,
      },
    },
    icon: {
      padding: 10,
      margin: 0,
      borderBottom: "2px solid transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      position: "relative",
      "&:hover": {
        background: "transparent",
        // "&::after": {
        //   background: "rgba(0, 0, 0, 0.04)",
        // },
        "& span": {
          background: "transparent",
          borderRadius: 50,
          "& span": {
            "& span": {
              background: Colors.basfOrange,
            },
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: 0,
        height: 54,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "relative",
      },
    },
    activeIcon: {
      [theme.breakpoints.down("xs")]: {
        "&:after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          height: 2,
          background: "#004A96",
          width: "25px",
          left: "50% !important",
          transform: "translateX(-50%)",
        },
      },
    },
    badgeIcon: {
      "&:after": {
        left: -4,
      },
    },
    badge: {
      background: Colors.basfOrange,
      color: theme.palette.secondary.main,
      top: 4,
      [theme.breakpoints.down("xs")]: {
        height: 13,
        width: 13,
        minWidth: "unset",
        lineHeight: "13px",
        fontSize: "10px",
      },
    },
    largeCountBadge: {
      "& .MuiBadge-badge": {
        width: 20,
        height: 20,
        right: "-2px",
      },
    },
    svg: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: theme.transitions.create("fillOpacity", {
        duration: "250ms",
      }),
    },
  })
);
