import { CSSProperties, useMemo } from "react";
import {
  Button,
  Fade,
  Modal,
  Typography,
  IconButton,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./SuccessPopup.styles";
import { ReactComponent as CheckCircle } from "../../../assets/icons/checkCircle.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cross-icon-grey.svg";
import clsx from "clsx";

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  buttonText: string;
  subTitle?: string | JSX.Element;
  subTitleStyle?: CSSProperties;
  size?: "large" | "medium";
  underButtonText?: string | JSX.Element;
}

const SuccessPopup = (props: IProps) => {
  const {
    open,
    onClose,
    title,
    subTitle,
    buttonText,
    onSubmit,
    subTitleStyle = {},
    size = "medium",
    underButtonText,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const SubTitle = useMemo(() => {
    if (!subTitle) return null;
    return (
      <Box>
        <Typography
          className={clsx(classes.description, {
            [classes.descriptionLarge]: size === "large",
          })}
          align="center"
          variant={isMobile ? "body2" : "body1"}
          style={subTitleStyle}
        >
          {subTitle}
        </Typography>
      </Box>
    );
  }, [
    subTitle,
    classes.description,
    classes.descriptionLarge,
    size,
    isMobile,
    subTitleStyle,
  ]);

  return (
    <Modal
      open={open}
      className={clsx(classes.modal, {
        [classes.modalLarge]: size === "large",
      })}
    >
      <Fade in={open}>
        <Paper
          className={clsx(classes.paper, {
            [classes.paperLarge]: size === "large",
          })}
          square
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.closeButton}
          >
            <IconButton onClick={onClose}>
              <CloseIcon width={"16px"} height={"16px"} />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircle className={classes.icon} />
            <Typography
              align="center"
              variant={isMobile ? "h5" : "h3"}
              color="primary"
              className={clsx({
                [classes.titleLarge]: size === "large",
              })}
            >
              {title}
            </Typography>
            {SubTitle}
            <Box
              className={clsx(classes.submitButton, {
                [classes.submitButtonLarge]: size === "large",
              })}
            >
              <Button variant="contained" onClick={onSubmit}>
                {buttonText}
              </Button>
            </Box>
            {!!underButtonText && (
              <Box>
                <Typography
                  className={clsx(classes.description, {
                    [classes.descriptionLarge]: size === "large",
                  })}
                  align="center"
                  variant={isMobile ? "body2" : "body1"}
                  style={{
                    ...subTitleStyle,
                  }}
                >
                  {underButtonText}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SuccessPopup;
