import { useTranslation } from "react-i18next";
import { lazy, useMemo } from "react";
import Favorites from "../containers/Favorites/Favorites";
import FullWidthLayout from "../components/FullWidthLayout/FullWidthLayout";

import { Route } from "../types/Common";
import { Roles } from "./roles";

const Home = lazy(() => import("../containers/Home/Home"));
const Prizes = lazy(() => import("../containers/Prizes/Prizes"));
const Prize = lazy(() => import("../containers/Prize/Prize"));
const Products = lazy(() => import("../containers/Products/Products"));
const About = lazy(() => import("../containers/About/About"));
const FAQ = lazy(() => import("../containers/FAQ/FAQ"));
const Terms = lazy(() => import("../containers/Terms/Terms"));
const Privacy = lazy(() => import("../containers/PrivacyPolicy/PrivacyPolicy"));
const Cart = lazy(() => import("../containers/Cart/Cart"));
const Profile = lazy(() => import("../containers/MyProfile/MyProfile"));
const Checkout = lazy(() => import("../containers/Checkout/Checkout"));
const Invoice = lazy(() => import("../containers/Invoice/Invoice"));
const MyInvoices = lazy(() => import("../containers/MyInvoices/MyInvoices"));
const MyPointsHistory = lazy(
  () => import("../containers/MyPointsHistory/MyPointsHistory")
);
const MyOrders = lazy(() => import("../containers/MyOrders/MyOrders"));
const ChangePassword = lazy(
  () => import("../containers/ChangePassword/ChangePassword")
);
const Login = lazy(() => import("../containers/Login/Login"));
const SignUp = lazy(() => import("../containers/SignUp/SignUp"));
const ForgotPassword = lazy(
  () => import("../containers/ForgotPassword/ForgotPassword")
);
const CreatePassword = lazy(
  () => import("../containers/CreatePassword/CreatePassword")
);
const Landing = lazy(() => import("../containers/Landing/Landing"));
const DistributorSignUp = lazy(
  () => import("../containers/DistributorSignUp/DistributorSignUp")
);
const ActivateDistributor = lazy(
  () => import("../containers/ActivateDistributor/ActivateDistributor")
);
const Survey = lazy(() => import("../containers/Survey/Survey"));
const NotFound = lazy(() => import("../containers/NotFound/NotFound"));

export const pathMap = {
  en: {
    home: "/landing",
    prizes: "/prizes/:category?",
    prizeItem: "/prizes/:prizeName/:prizeId",
    products: "/products/:category?",
    favorites: "/favorites",
    faq: "/faq",
    terms: "/terms",
    privacyPolicy: "/privacy-policy",
    about: "/about",
    cart: "/cart",
    profile: "/profile",
    checkout: "/checkout",
    invoice: "/invoice",
    myInvoices: "/my-invoices",
    myPointsHistory: "/points-history",
    myOrders: "/my-orders",
    changePassword: "/change-password",
    login: "/login",
    signup: "/signup",
    createPassword: "/auth/create-password",
    forgotPassword: "/forgot-password",
    landing: "/",
    distributorSignUp: "/signup-distributor",
    activateDistributor: "/auth/distributor/activate",
    survey: "/survey/:surveyId",
    noPrize: "/no-prize",
  },
  lt: {
    home: "/sveiki",
    prizes: "/prizai/:category?",
    prizeItem: "/prizai/:prizeName/:prizeId",
    products: "/produktai/:category?",
    favorites: "/megstamiausi",
    faq: "/duk",
    terms: "/terminai-ir-salygos",
    privacyPolicy: "/privatumo-politika",
    about: "/apie",
    cart: "/krepselis",
    profile: "/profilis",
    checkout: "/uzsakymas",
    invoice: "/saskaitos",
    myInvoices: "/mano-saskaitos",
    myPointsHistory: "/tasku-istorija",
    myOrders: "/mano-uzsakymai",
    changePassword: "/keisti-slaptazodi",
    login: "/prisijungti",
    signup: "/registruotis",
    createPassword: "/auth/create-password",
    forgotPassword: "/slaptazodzio-priminimas",
    landing: "/",
    distributorSignUp: "/registruotis-distributoriai",
    activateDistributor: "/auth/distributor/activate",
    survey: "/survey/:surveyId",
    noPrize: "/no-prize",
  },
  lv: {
    home: "/sveiki",
    prizes: "/balvas/:category?",
    prizeItem: "/balvas/:prizeName/:prizeId",
    products: "/produkti/:category?",
    favorites: "/favoriti",
    faq: "/buj",
    terms: "/termini-un-nosacijumi",
    privacyPolicy: "/privatuma-politika",
    about: "/par",
    cart: "/groza",
    profile: "/profils",
    checkout: "/pasutijums",
    invoice: "/rekinu",
    myInvoices: "/mani-rekini",
    myPointsHistory: "/punkti-vesture",
    myOrders: "/mani-pasutijumi",
    changePassword: "/mainit-paroli",
    login: "/pierakstities",
    signup: "/registreties",
    createPassword: "/auth/create-password",
    forgotPassword: "/aizmirsat-paroli",
    landing: "/",
    distributorSignUp: "/registreties-izplatitajs",
    activateDistributor: "/auth/distributor/activate",
    survey: "/survey/:surveyId",
    noPrize: "/no-prize",
  },
  et: {
    home: "/tere",
    prizes: "/auhinnad/:category?",
    prizeItem: "/auhinnad/:prizeName/:prizeId",
    products: "/tooted/:category?",
    favorites: "/lemmikud",
    faq: "/kkk",
    terms: "/kasutustingimused",
    privacyPolicy: "/privaatsuspoliitika",
    about: "/meist",
    cart: "/ostukorvi",
    profile: "/profiili",
    checkout: "/tellimus",
    invoice: "/arveid",
    myInvoices: "/minu-arved",
    myPointsHistory: "/punktide-ajalugu",
    myOrders: "/minu-tellimused",
    changePassword: "/parooli-muutmine",
    login: "/logi-sisse",
    signup: "/registreeru",
    createPassword: "/auth/create-password",
    forgotPassword: "/unustasid-parooli",
    landing: "/",
    distributorSignUp: "/registreeru-turustaja",
    activateDistributor: "/auth/distributor/activate",
    survey: "/survey/:surveyId",
    noPrize: "/no-prize",
  },
};

export const usePaths = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const paths = useMemo(
    () => pathMap[language as "en" | "lv" | "lt" | "et"],
    [language]
  );

  return paths;
};

export const useRoutes = (): Route[] => {
  const paths = usePaths();
  const { i18n } = useTranslation();
  const { language } = i18n;

  return [
    {
      path: paths.home,
      component: Home,
      disableBorder: true,
    },
    {
      path: paths.prizes,
      component: Prizes,
      layout: FullWidthLayout,
    },
    {
      path: paths.prizeItem,
      component: Prize,
    },
    {
      path: paths.products,
      component: Products,
      roles: [Roles.basfUser],
    },
    {
      path: paths.favorites,
      component: Favorites,
    },
    {
      path: paths.faq,
      component: FAQ,
      layout: FullWidthLayout,
    },
    {
      path: paths.terms,
      component: Terms,
      layout: FullWidthLayout,
      public: true,
    },
    {
      path: paths.privacyPolicy,
      component: Privacy,
      layout: FullWidthLayout,
      public: true,
    },
    {
      path: paths.about,
      component: About,
      layout: FullWidthLayout,
      disableBorder: true,
    },
    {
      path: paths.cart,
      component: Cart,
    },
    {
      path: paths.profile,
      component: Profile,
    },
    {
      path: paths.checkout,
      component: Checkout,
    },
    {
      path: paths.invoice,
      ...(language === "lv" && { altPath: "/reķinu" }),
      component: Invoice,
      roles: [Roles.basfUser],
    },
    {
      path: paths.myInvoices,
      ...(language === "lv" && { altPath: "/mani-reķini" }),
      component: MyInvoices,
      roles: [Roles.basfUser],
    },
    {
      path: paths.myPointsHistory,
      ...(language === "lv" && { altPath: "/punkti-vēsture" }),
      component: MyPointsHistory,
    },
    {
      path: paths.myOrders,
      component: MyOrders,
    },
    {
      path: paths.changePassword,
      component: ChangePassword,
    },
    {
      path: paths.login,
      component: Login,
      public: true,
      layout: FullWidthLayout,
      disableBorder: true,
    },
    {
      path: paths.signup,
      component: SignUp,
      public: true,
    },
    {
      path: paths.forgotPassword,
      component: ForgotPassword,
      public: true,
    },
    {
      path: paths.createPassword,
      component: CreatePassword,
      public: true,
    },
    {
      path: paths.landing,
      component: Landing,
      public: true,
      layout: FullWidthLayout,
      disableBorder: true,
    },
    {
      path: paths.distributorSignUp,
      ...(language === "lv" && { altPath: "/registreties-izplatītājs" }),
      component: DistributorSignUp,
      public: true,
    },
    {
      path: paths.activateDistributor,
      component: ActivateDistributor,
      public: true,
    },
    {
      path: paths.survey,
      layout: FullWidthLayout,
      component: Survey,
    },
    {
      path: paths.noPrize,
      component: NotFound,
      public: true,
    },
  ];
};
