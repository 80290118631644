import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "transparent",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    paddingRight: theme.spacing(4),
    paddingLeft: "24px",
    borderRight: "2px solid #004A96",
    fontSize: theme.spacing(2),
    lineHeight: "32px",
    height: "32px",
    whiteSpace: "nowrap",
    color: Colors.lightBlue,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: theme.spacing(1),
    },
    "&:hover": {
      fontWeight: "bold",
      color: Colors.darkBlue,
    },
  },
  points: {
    fontFamily: "Helvetica Neue LT W05_45 Light",
    fontWeight: 600,
    color: theme.palette.primary.main,
    paddingLeft: "4px",
  },
  icon: {
    paddingLeft: theme.spacing(0.5),
    width: 22,
    "& svg": {
      minWidth: 16,
      width: 16,
      minHeight: 16,
      height: 16,
    },
  },
}));
