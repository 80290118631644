import { ChangeEvent, ReactNode } from "react";
import { Link } from "./Common";
import { FAQ } from "./FAQ";
import { CategoryPrize, SortOptions } from "./Prizes";
import { PopperProps } from "@material-ui/core";
import { AttributeOption } from "./Cart";

export interface AccoItemPropsTypes {
  index: number;
  accoData: FAQ;
  hideQuestion?: boolean;
  iconByIndex?: boolean;
}

export interface ProfileListPropsType {
  onClose: () => void;
  translationKey: string;
}
export interface NavItemPropsType {
  path: string;
  translationKey: string;
  children: any;
  id: string | undefined;
  rotation: string;
  closePopover: () => void;
  openPopover: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  open: boolean;
  anchorEl: HTMLElement | null;
  openKey: string;
  setOpenKey: React.Dispatch<React.SetStateAction<string>>;
}
export interface MobileNavMenuListTypes {
  translationKey: string;
  closePopover: () => void;
  subroutes: Array<Link[]>;
}
export interface LanguagesListPropsType {
  onClose: () => void;
  translationKey: string;
}
export interface HeaderButtonPropsType {
  onClickHandle: (event: React.MouseEvent<HTMLElement>) => void;
}
export interface MobileMenusType {
  toggleMobileMenu: () => void;
  openMobileMenu: boolean;
  onClose: () => void;
}
export interface RoutesHeaderType {
  header: string;
  points: number;
}
export interface NoResultFoundPropType {
  icon: ReactNode;
  info: string;
  path?: string;
  link?: string;
}
export interface FavoritesListPropsType {
  onClose: () => void;
  translationKey: string;
}
export interface NoResultViewPropType {
  path: string;
  title: string;
}
export interface NavMenuPropsType {
  closeExtras: () => void;
}

export interface PaginationTypes {
  page: number;
  onPageChange: (page: number) => void;
  pages: number;
  totalCount?: number;
  currentCount?: number;
  initialPages?: number;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
  showMore?: boolean;
}
export interface ShowMoreTypes {
  page: number;
  pages: number;
  totalCount?: number;
  currentCount?: number;
  onNextPage: () => void;
}
export type ActivePopperType =
  | "languages"
  | "profile"
  | "favorites"
  | "bag"
  | "";
export interface MyBagListPropsType {
  onClose?: () => void;
  translationKey: string;
}
export interface NoResultViewPropType {
  path: string;
  title: string;
  info: string;
}

export enum categoryActions {
  set = 0,
  remove = 1,
}
export interface CategoryFilterPropsType {
  inputValue: string;
  categories: Array<CategoryPrize>;
  setCategory: (key: categoryActions, data?: CategoryPrize) => void;
  handleInputChange: (input: string) => void;
}

export interface PointFilterPropsType {
  min: number;
  max: number;
  value: number[];
  handlePriceSliderChange: (event: any, newValue: number | number[]) => void;
  handleFilterByPrice: () => void;
}
export interface SearchPrizesPropsTypes {
  setNewTerm: (term: string) => void;
  handleSubmitSearch: () => void;
  handleInputChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  loading: boolean;
  inputValue: string;
  clearSearch: () => void;
}
export interface SortPrizesItemPropsTypes {
  onClick: () => void;
}
export interface SortPrizesPropsType {
  options: Array<SortOptions>;
  onSort: (sort: string) => void;
}

export interface SortPopperPropsType extends PopperProps {
  width: string;
  onDefaultSet: (value: string) => void;
  type: string;
}

export interface QuestionPropsType {
  onSent: (data: ContactUsFormType) => void;
}
export interface ContactUsFormType {
  email: string;
  message: string;
}
export interface ListItemPopperPropsType {
  imageUrl: string;
  title?: string;
  points: number;
  onDelete: (id: number, title?: string) => void;
  itemId: number;
  isScroll: number;
  quantity?: number;
  subtitle: string | null;
  attributeOptions?: AttributeOption[];
}

export interface ListContentPropsType {
  points: number;
  buttonName: string;
  onClick: () => void;
  children: ReactNode;
  isScroll: number;
}
export interface ListWrapperPropsTypes {
  onClick: () => void;
  children: ReactNode;
  translationKey: string;
  isItems: boolean;
}

export interface NothingFoundProps {
  term: string;
}
