import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: "54px",
      left: 0,
      right: 0,
    },
    menuPaper: {
      paddingTop: 12,
      paddingBottom: 20,
      overflow: "hidden",
      maxHeight: "calc(100vh - 135px)",
    },
    menuBoxWrapper: {
      overflowY: "auto",
      maxHeight: "calc(90vh - 135px - 40px)",
    },
    button: {
      cursor: "pointer",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    title: {
      width: "100%",
      color: theme.palette.primary.main,
      fontSize: "18px",
      lineHeight: "21.98px",
      cursor: "pointer !important",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
    active: {
      fontWeight: "bold",
    },
    accordionRoot: {
      "&:before": {
        background: "transparent",
      },
      "&.Mui-expanded": {
        margin: 0,
      },
    },
    accordionSummaryRoot: {
      margin: 0,
      padding: "8px 32px",
      minHeight: "unset",
      "&.Mui-expanded": {
        minHeight: "unset",
      },
    },
    accordionSummaryContent: {
      margin: "12px 0",
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
    roundArrow: {
      transition: theme.transitions.create(["width", "margin-right"]),
    },
    childAccordionDetails: {
      padding: "0 32px",
    },
  })
);
