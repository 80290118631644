import { useCallback, useMemo } from "react";
import {
  Box,
  TableRow,
  TableCell,
  CardMedia,
  Button,
  Divider,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import QuantityField from "../../QuantityField/QuantityField";
import { useStyles } from "./PrizeListItem.styles";
import { PrizeList } from "../../../types/Prizes";
import { ReactComponent as CartIcon } from "../../../assets/icons/cart.svg";
import useImageUrl from "../../../hooks/useImageUrl";

interface IProps {
  prize: PrizeList;
  type: "cart" | "favorites";
  addToHandler: (id: number, title?: string, prizeId?: string) => void;
  handleDelete: (id: number, title: string) => void;
  handleChangeQuantity?: (quantity: number, id: number) => void;
}

export default function PrizeListItem(props: IProps) {
  const { prize, addToHandler, handleChangeQuantity, handleDelete, type } =
    props;
  const { id } = prize;
  const { id: prizeId, title, images, subTitle } = prize?.prize;
  const { amountInPoints, itemQuantity, missingPointsAmount } = prize?.totals;
  const classes = useStyles();
  const { t } = useTranslation();
  const imageUrls = useImageUrl(images);

  const removeHandler = useCallback(() => {
    if (type === "cart") handleDelete(id, title);
    else if (type === "favorites") handleDelete(prizeId, title);
  }, [handleDelete, id, prizeId, title, type]);

  const addToFavoritesButton = useMemo(() => {
    if (type === "cart")
      return (
        <>
          <Button
            color="primary"
            onClick={() => addToHandler(id, title)}
            className={classes.actionButton}
          >
            {t("cart.move_to_favorites")}
          </Button>
          <Divider orientation="vertical" className={classes.divider} />
        </>
      );

    return null;
  }, [type, classes.actionButton, classes.divider, t, addToHandler, id, title]);

  const quantityCell = useMemo(() => {
    if (type === "cart" && handleChangeQuantity)
      return (
        <TableCell>
          <QuantityField
            quantity={itemQuantity}
            onChange={(quantity) => handleChangeQuantity(quantity, id)}
          />
        </TableCell>
      );

    return null;
  }, [itemQuantity, type, handleChangeQuantity, id]);

  const AddToCartCell = useMemo(() => {
    if (type === "favorites")
      return (
        <TableCell align="right">
          <Button
            variant="contained"
            className={classes.addToCartBtn}
            startIcon={<CartIcon />}
            disabled={prize?.prize?.outOfStock}
            onClick={() => addToHandler(id, title, String(prizeId))}
          >
            {t(
              prize?.prize?.outOfStock
                ? "buttons.out_of_stock"
                : "buttons.add_to_bag"
            )}
          </Button>
        </TableCell>
      );
    return null;
  }, [
    type,
    classes.addToCartBtn,
    prize?.prize?.outOfStock,
    t,
    addToHandler,
    id,
    title,
    prizeId,
  ]);

  const MissingPointsText = useMemo(() => {
    if (missingPointsAmount > 0)
      return (
        <Box pt="12px">
          <Typography variant="body1" color="textSecondary">
            {t("prizes.you_need_extra_points_to_order").replace(
              "{points}",
              missingPointsAmount.toString()
            )}
          </Typography>
        </Box>
      );

    return null;
  }, [missingPointsAmount, t]);

  return (
    <TableRow className={classes.tableRow}>
      <TableCell scope="row" style={{ paddingRight: "82px" }}>
        <CardMedia
          title={title}
          image={imageUrls?.[0]}
          className={classes.image}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column" height={1}>
          <Typography variant="h4" className={classes.title}>
            {title}
            <br />
            {subTitle}
          </Typography>
          {MissingPointsText}
          <Box className={classes.buttonContainer}>
            {addToFavoritesButton}
            <Button
              color="primary"
              className={classes.actionButton}
              onClick={removeHandler}
            >
              {t("cart.remove")}
            </Button>
          </Box>
        </Box>
      </TableCell>
      {quantityCell}
      <TableCell>
        <Typography variant="h4" className={classes.points}>
          {amountInPoints}
        </Typography>
      </TableCell>
      {AddToCartCell}
    </TableRow>
  );
}
