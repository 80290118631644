import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 9,
    [theme.breakpoints.down("xs")]: {
      left: "-5px !important",
    },
  },
}));
