import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./CouponField.styles";
import { useCallback } from "react";
import ContainedButton from "../ContainedButton/ContainedButton";

interface ICouponField {
  onInputChange: (input: string) => void;
  onSubmit: () => void;
  value: string;
  error?: string;
  hide?: boolean;
  loading?: boolean;
  appliedCouponCode?: string;
}

export const CouponField = (props: ICouponField) => {
  const {
    onInputChange,
    onSubmit,
    value,
    error,
    hide,
    loading,
    appliedCouponCode,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCouponInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange(event.target.value);
    },
    [onInputChange]
  );

  if (!!hide) return null;

  return (
    <Box mb={8}>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        <TextField
          variant="outlined"
          placeholder={t("invoice.enter_coupon_code")}
          className={classes.couponInput}
          onChange={handleCouponInput}
          value={value}
        />
        <ContainedButton
          loading={loading}
          label={t("invoice.apply")}
          variant="contained"
          color="primary"
          className={classes.applyCoupon}
          onClick={onSubmit}
        />
      </Box>
      {error && (
        <Box mt={1}>
          <Typography color="error" variant="body1">
            {error}
          </Typography>
        </Box>
      )}
      {!!appliedCouponCode && (
        <Box mt={1} display="flex">
          <Typography variant="body1">
            {`${t("invoice.applied_coupon")}:`}
          </Typography>
          <Typography
            color="primary"
            variant="body1"
            className={classes.couponCode}
          >
            {appliedCouponCode}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
