import { useStyles } from "./ContainedButton.styles";
import clsx from "clsx";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";

interface Props extends ButtonProps {
  loading?: boolean;
  label: string;
}

export default function ContainedButton({ loading, label, ...rest }: Props) {
  const classes = useStyles();
  return (
    <Button
      disabled={loading}
      className={clsx({
        [classes.transparentText]: loading,
      })}
      {...rest}
    >
      {label}
      {loading && (
        <CircularProgress
          className={classes.progress}
          size={20}
          color="primary"
        />
      )}
    </Button>
  );
}
