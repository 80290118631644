import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationLV from "./locales/lv.json";
import translationET from "./locales/et.json";
import translationLT from "./locales/lt.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: translationEN,
    },
    lt: {
      translations: translationLT,
    },
    et: {
      translations: translationET,
    },
    lv: {
      translations: translationLV,
    },
  },
  compatibilityJSON: "v2",
  fallbackLng: localStorage.getItem("basf-lang") || "lt",
  debug: false,
  lng: localStorage.getItem("basf-lang") || "lt",
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    useSuspense: true,
  },
});

export default i18n;
