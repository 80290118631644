import { Box, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { NoResultFoundPropType } from "../../types/Components";
import { useStyles } from "./NoResultsFound.styles";

const NoResultsFound: React.FC<NoResultFoundPropType> = ({
  icon,
  info,
  link,
  path,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const Link = useMemo(() => {
    if (!!link && !!path)
      return (
        <NavLink to={path} className={classes.link}>
          {link}
        </NavLink>
      );
  }, [link, path, classes.link]);

  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>{icon}</Box>
      <Typography variant="h3" component={Box}>
        {t("errors.no_results")}
      </Typography>
      <Typography variant="h5" className={classes.info}>
        {info}
      </Typography>
      {Link}
    </Box>
  );
};
export default NoResultsFound;
