import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      padding: "16px 24px 16px 24px",
      marginTop: 8,
      borderBottom: "3px solid rgba(0,74,150,1)",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
      [theme.breakpoints.down("xs")]: {
        border: "none",
        width: "100%",
        padding: "32px 32px 12px 32px",
      },
    },
    list: {
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        paddingTop: "12px",
      },
    },
    item: {
      cursor: "pointer",
      letterSpacing: "0.03em",
      padding: "16px 0",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("xs")]: {
        padding: "20px 0",
      },
      "&:hover": {
        "& .MuiTypography-root": {
          color: theme.palette.primary.main,
          textDecoration: "none",
        },
      },
    },
    itemText: {
      lineHeight: "16px",
    },
    active: {
      color: theme.palette.primary.main,
      fontFamily: "Helvetica Neue LT W05_65 Medium",
    },
  })
);
