import { Box, Typography, Button } from "@material-ui/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListContentPropsType } from "../../../../types/Components";
import { useStyles } from "./ListContentStyles";

const ListContent: React.FC<ListContentPropsType> = ({
  points,
  onClick,
  buttonName,
  children,
  isScroll,
}) => {
  const innerHeight = useMemo(() => window.innerHeight, []);
  const classes = useStyles({ isScroll, innerHeight });
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.list}>{children}</Box>
      <Box className={classes.pointsWrap}>
        <Typography className={classes.subtitle} variant="h5" color="primary">
          {t("header.total_points")}:
        </Typography>
        <Typography variant="h4" color="primary">
          {points}
        </Typography>
      </Box>
      <Button
        className={classes.btn}
        color="primary"
        onClick={onClick}
        disableElevation
        variant="contained"
      >
        {t(buttonName)}
      </Button>
    </>
  );
};
export default ListContent;
