import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import useSWR, { mutate } from "swr";
import { Endpoints } from "../api/endpoints";
import { OnlyCart, OnlyCartPrize, UseCart } from "../types/Cart";
import { mutateData } from "../api/api";
import { useAuth } from "./Auth";
import { useUtag } from "./Utag";

const initialCartValues = {
  showCartPopper: false,
  cart: {
    prizes: [],
    totals: {
      amountInPoints: 0,
      itemQuantity: 0,
      missingPointsAmount: 0,
    },
  },
  cartPrizes: [],
  cartTotals: {
    amountInPoints: 0,
    itemQuantity: 0,
    missingPointsAmount: 0,
  },
  cartError: null,
  loading: false,
  deleteFromCart: () => {},
  updateQuantity: () => {},
  addToFavorites: () => {},
  addToCart: () => {},
  setShowCart: () => {},
};

const cartContext = createContext<UseCart>(initialCartValues);

export const useCart = () => {
  return useContext(cartContext);
};

export const CartProvider = ({ children }: PropsWithChildren<{}>) => {
  const cart = useProvideCart();
  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
};

export const useProvideCart = (): UseCart => {
  const { isLoggedIn } = useAuth();
  const {
    data: cart,
    error: cartError,
    mutate: mutateCart,
    isValidating,
  } = useSWR<OnlyCart, any>(isLoggedIn ? Endpoints.cart : null);
  const { linkUtagAction } = useUtag();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCart, setShowCart] = useState<boolean>(false);

  const loading = useMemo(
    () => isValidating || isLoading,
    [isLoading, isValidating]
  );

  const parsedCartItems = useMemo(
    () =>
      cart?.prizes?.map((prize) => ({
        id: prize.prizeId,
        quantity: prize.totals.itemQuantity,
        cartId: prize.cartPrizeId,
      })) ?? [],
    [cart?.prizes]
  );

  const updateQuantity = useCallback(
    async (quantity: number, id: number) => {
      setIsLoading(true);
      await mutateData("patch", `${Endpoints.cartPrize}/${id}`, {
        quantity: quantity,
      }).then(async () => {
        await mutateCart();
        await mutate(Endpoints.order);
        setIsLoading(false);
      });
    },
    [mutateCart]
  );

  const addToFavorites = useCallback(
    (id: number, title?: string) => {
      setIsLoading(true);
      const foundItem = parsedCartItems.find((item) => item.cartId === id);
      mutateData("post", `${Endpoints.cartPrize}/${id}/move-to-favorite`).then(
        async () => {
          await mutateCart();
          await mutate(Endpoints.favorites);
          await mutate(Endpoints.order);
          setIsLoading(false);
        }
      );
      if (foundItem) {
        linkUtagAction({
          event_category: "click",
          event_action: "button",
          event_value: `add_favourites_cart||${foundItem.id}`,
          cart_id: String(id),
          product_id: String(foundItem.id),
          product_name: title || "",
          product_quantity: String(foundItem.quantity),
        });
        if (title) {
          linkUtagAction({
            event_category: "cart",
            event_action: "update",
            event_value: `update||${id}`,
            cart_id: String(id),
            cart_quantity: String(0),
            product_id: String(foundItem.id),
            product_name: "",
            product_quantity: String(0),
          });
          linkUtagAction({
            event_category: "product",
            event_action: "remove_from_cart",
            event_value: `remove_from_cart||${foundItem.id}`,
            cart_id: String(id),
            product_id: String(foundItem.id),
            product_name: title,
            product_quantity: String(foundItem.quantity),
          });
        }
      }
    },
    [linkUtagAction, mutateCart, parsedCartItems]
  );

  const deleteFromCart = useCallback(
    async (id: number, title: string) => {
      setIsLoading(true);
      const foundItem = parsedCartItems.find((item) => item.cartId === id);
      if (foundItem) {
        linkUtagAction({
          event_category: "cart",
          event_action: "update",
          event_value: `update||${id}`,
          cart_id: String(id),
          cart_quantity: String(0),
          product_id: String(foundItem.id),
          product_name: title,
          product_quantity: String(0),
        });
        linkUtagAction({
          event_category: "product",
          event_action: "remove_from_cart",
          event_value: `remove_from_cart||${foundItem.id}`,
          cart_id: String(id),
          product_id: String(foundItem.id),
          product_name: title,
          product_quantity: String(foundItem.quantity),
        });
      }
      await mutateData("delete", `${Endpoints.cartPrize}/${id}`).then(
        async (res: any) => {
          await mutateCart();
          await mutate(Endpoints.order);
          setIsLoading(false);
        }
      );
    },
    [linkUtagAction, mutateCart, parsedCartItems]
  );

  const addToCart = useCallback(
    async (id: number, quantity?: number, options?: number[]) => {
      // old logic to check if a prize is already in cart
      // const foundItem = parsedCartItems.find((item) => item.id === id);
      // const foundQuantity = foundItem?.quantity;
      // if prize is in cart already with the same quantity do nothing
      // if (!!foundItem && foundQuantity === quantity) return;
      // if prize is in cart already, update the quantity
      // if (!!foundItem && !!quantity) {
      //   await updateQuantity(quantity, foundItem.cartId)
      //     .then(() => setShowCart(true))
      //     .finally(() => {
      //       setIsLoading(false);
      //     });
      //   return;
      // }

      setIsLoading(true);

      mutateData("post", `${Endpoints.addToCart}/${id}`, {
        quantity,
        options,
      })
        .then(async ({ data }) => {
          await mutateCart();
          await mutate(Endpoints.order);
          const foundItem: OnlyCartPrize = data.prizes.find(
            (cart: OnlyCartPrize) => cart.prizeId === id
          );
          if (foundItem) {
            linkUtagAction({
              event_category: "cart",
              event_action: "creation",
              event_value: `creation||${foundItem.cartPrizeId}`,
              cart_id: String(foundItem.cartPrizeId),
              cart_quantity: String(foundItem.totals.itemQuantity),
              product_id: String(id),
              product_name: foundItem.title,
              product_quantity: String(quantity || 1),
            });
            linkUtagAction({
              event_category: "product",
              event_action: "add_to_cart",
              event_value: `add_to_cart||${id}`,
              cart_id: String(foundItem.cartPrizeId),
              product_id: String(id),
              product_name: foundItem.title,
              product_quantity: String(quantity),
            });
          }
          setShowCart(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [linkUtagAction, mutateCart]
  );

  return {
    cart: cart,
    cartPrizes: cart?.prizes ?? null,
    cartTotals: cart?.totals ?? null,
    cartError,
    loading,
    updateQuantity,
    addToFavorites,
    deleteFromCart,
    addToCart,
    showCartPopper: showCart,
    setShowCart,
  };
};
