import { List } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { NavMenuPropsType } from "../../../types/Components";
import useLinksUtil from "../../../utils/Links";
import NavItem from "./NavItem/NavItem";
import { useStyles } from "./NavMenu.styles";

const NavMenu: React.FC<NavMenuPropsType> = ({ closeExtras }) => {
  const classes = useStyles();
  const { navLinks } = useLinksUtil();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openKey, setOpenKey] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handlePopoverOpen = useCallback(
    (key: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
      setOpenKey(key);
      closeExtras();
      setOpen(true);
    },
    [closeExtras]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setOpenKey("");
    setOpen(false);
  }, []);

  const hasAnchor = useMemo(() => !!anchorEl, [anchorEl]);

  const id = useMemo(
    () => (hasAnchor ? "mouse-over-popover" : undefined),
    [hasAnchor]
  );

  const rotation = useMemo(
    () => (hasAnchor ? "rotateZ(180deg)" : "rotateZ(0deg)"),
    [hasAnchor]
  );

  const navMap = useMemo(
    () =>
      navLinks?.map((link) => (
        <NavItem
          path={link.path}
          key={link.key}
          children={link.children}
          translationKey={link.key}
          id={id}
          rotation={rotation}
          closePopover={handlePopoverClose}
          openPopover={handlePopoverOpen(link.key)}
          open={hasAnchor && open}
          anchorEl={anchorEl}
          openKey={openKey}
          setOpenKey={setOpenKey}
        />
      )),
    [
      anchorEl,
      handlePopoverClose,
      handlePopoverOpen,
      hasAnchor,
      id,
      navLinks,
      open,
      openKey,
      rotation,
    ]
  );

  return <List className={classes.root}>{navMap}</List>;
};

export default NavMenu;
