import {
  Box,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ClosePopperItem } from "../../../../assets/icons/closeIcon.svg";
import { ListItemPopperPropsType } from "../../../../types/Components";
import { useStyles } from "./ListItemStyles";

const ListItem: React.FC<ListItemPopperPropsType> = ({
  imageUrl,
  title,
  points,
  onDelete,
  itemId,
  isScroll,
  quantity,
  subtitle,
  attributeOptions,
}) => {
  const classes = useStyles(isScroll);
  const { t } = useTranslation();

  const QuantityField = useMemo(
    () => (!!quantity ? `${t("common.quantity")}: ${quantity}` : null),
    [quantity, t]
  );

  const Attributes = useMemo(() => {
    if (attributeOptions && attributeOptions.length)
      return (
        <Box pt="4px">
          {attributeOptions.map((option) => (
            <Typography
              key={option.id}
              color="textSecondary"
              variant="subtitle2"
            >
              {option.attributeName}: {option.title}
            </Typography>
          ))}
        </Box>
      );
    return null;
  }, [attributeOptions]);

  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      onDelete(itemId, title);
    },
    [itemId, onDelete, title]
  );

  return (
    <Box className={classes.root}>
      <CardMedia image={imageUrl} className={classes.img} />
      <Box className={classes.wrapper}>
        <Typography variant="h6" className={classes.title}>
          {title}
          <br />
          {subtitle}
        </Typography>
        {Attributes}
        <Box className={classes.pointContent}>
          <Typography
            color="textSecondary"
            variant="subtitle2"
            className={classes.quantity}
          >
            {QuantityField}
          </Typography>
          <Typography variant="body1" className={classes.points}>
            {points}
          </Typography>
        </Box>
      </Box>
      <IconButton
        className={classes.closeBtn}
        onClick={handleDelete}
        size="medium"
      >
        <ClosePopperItem />
      </IconButton>
      <Divider />
    </Box>
  );
};
export default ListItem;
