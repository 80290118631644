import { useCallback, useState, useMemo } from "react";
import { mutateData } from "../api/api";
import { Endpoints } from "../api/endpoints";
import { FileUploadDetails, InvoiceTimer } from "../types/Invoices";
import fileDownloader from "js-file-download";
import useSWR from "swr";
import { useAuth } from "./Auth";

export const useInvoice = () => {
  const { isLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadDetails, setUploadDetails] = useState<FileUploadDetails>({});
  const [registeredFileIds, setRegisteredFileIds] = useState<string[]>([]);

  const addInvoice = useCallback(
    (item: any, voucher?: string) => {
      setIsLoading(true);
      const file: File = item.file;
      const data = new FormData();
      data.append("invoice[file]", file);
      if (voucher) data.append("voucher", voucher);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      mutateData(
        "post",
        `${Endpoints.addInvoice}`,
        data,
        headers,
        "any",
        (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadDetails((prev) => ({
            ...prev,
            [item.id]: { progress: percentCompleted },
          }));
        },
        item.source.token
      )
        .then((res) => {
          setIsLoading(false);
          setUploadDetails((prev) => ({
            ...prev,
            [item.id]: { progress: 101 },
          }));
        })
        .catch((err) => {
          setUploadDetails((prev) => ({
            ...prev,
            [item.id]: {
              progress: 101,
              error:
                err?.response?.data?.error?.message ??
                err?.response?.data?.formErrors?.[0]?.message,
            },
          }));
        })
        .finally(() => setRegisteredFileIds((prev) => [...prev, item.id]));
    },
    [setIsLoading]
  );

  const downloadInvoice = useCallback((id: number) => {
    const url = `/invoice/${id}/download`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };
    return mutateData("get", url, null, headers, "arraybuffer").then(
      (res: any) => {
        fileDownloader(res.data, `Invoice_${id}.pdf`);
      }
    );
  }, []);

  const { data: invoiceTimer, error: timerError } = useSWR<InvoiceTimer, any>(
    isLoggedIn ? Endpoints.invoiceTimer : null
  );

  const loadingTimer = useMemo(
    () => !invoiceTimer && !timerError,
    [invoiceTimer, timerError]
  );

  return {
    loading: isLoading,
    addInvoice,
    uploadDetails,
    setRegisteredFileIds,
    registeredFileIds,
    setUploadDetails,
    downloadInvoice,
    invoiceTimer,
    loadingTimer,
    timerError,
  };
};
