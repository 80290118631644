import { useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { usePaths } from "../../../constants/routes";
import { useGetImageUrl } from "../../../hooks/useImageUrl";
import { useCart } from "../../../state/Cart";
import { MyBagListPropsType } from "../../../types/Components";
import { convertToSlug } from "../../../utils/Common";
import ListContent from "../Lists/ListContent/ListContent";
import ListItem from "../Lists/ListItem/ListItem";
import ListWrapper from "../Lists/ListWrapper/ListWrapper";
import NoResultView from "../NoResultView/NoResultView";

const MyBagList: React.FC<MyBagListPropsType> = ({
  translationKey,
  onClose,
}) => {
  const { cart, deleteFromCart } = useCart();
  const history = useHistory();
  const paths = usePaths();
  const getImageUrl = useGetImageUrl();

  const onCloseHandler = useCallback(() => {
    onClose && onClose();
    history.push(paths.cart);
  }, [history, onClose, paths.cart]);

  const onHandleOrder = useCallback(
    (path: string) => {
      history.push(path);
      onClose && onClose();
    },
    [history, onClose]
  );

  const onDeleteItem = useCallback(
    (id: number, title?: string) => {
      deleteFromCart(id, title || "");
    },
    [deleteFromCart]
  );

  const isItems = useMemo(() => !cart, [cart]);

  const myCartContent = useMemo(() => {
    if (!cart)
      return (
        <NoResultView
          path={paths.prizes.replace("/:category?", "")}
          title="add_item"
          info={"empty_bag"}
        />
      );

    const myBagMap = cart.prizes.map((prize) => {
      const sanitizeTitle = prize?.slugTitle || convertToSlug(prize.title);
      const pathname = paths.prizeItem
        .replace(":prizeName", sanitizeTitle)
        .replace(":prizeId", `${prize.prizeId}`);
      return (
        <Link to={pathname} key={`header-bag-item-${prize.cartPrizeId}`}>
          <ListItem
            imageUrl={getImageUrl(prize.images[0]) as string}
            title={prize.title}
            quantity={prize.totals.itemQuantity}
            points={prize.totals.amountInPoints}
            itemId={prize.cartPrizeId}
            onDelete={onDeleteItem}
            isScroll={cart.prizes.length}
            subtitle={prize.subTitle}
            attributeOptions={prize.attributeOptions}
          />
        </Link>
      );
    });

    return (
      <ListContent
        points={cart.totals.amountInPoints}
        buttonName={"buttons.view_all"}
        onClick={() => onHandleOrder(paths.cart)}
        isScroll={cart.prizes.length}
      >
        {myBagMap}
      </ListContent>
    );
  }, [
    cart,
    getImageUrl,
    onDeleteItem,
    onHandleOrder,
    paths.cart,
    paths.prizeItem,
    paths.prizes,
  ]);

  return (
    <ListWrapper
      translationKey={translationKey}
      onClick={onCloseHandler}
      isItems={isItems}
    >
      {myCartContent}
    </ListWrapper>
  );
};
export default MyBagList;
