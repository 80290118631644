import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.25),
    backgroundColor: Colors.greyTransparent,
  },
  title: {
    fontSize: 14,
    lineHeight: "22px",
    color: Colors.darkBlue,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  time: {
    marginLeft: theme.spacing(0.5),
    fontFamily: "Helvetica Neue LT W05_75 Bold",
    fontSize: 14,
    lineHeight: "22px",
    color: Colors.darkBlue,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  warning: {
    backgroundColor: Colors.lightRedTransparentAlt,
  },
  warningTitle: {
    color: Colors.basfRed,
  },
  warningTime: {
    color: Colors.basfRed,
  },
}));
