import { makeStyles, createStyles, Theme } from "@material-ui/core";
import loginBg from "../../assets/images/login-bg.jpg";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      position: "relative",
      backgroundImage: `url("${loginBg}")`,
      backgroundSize: "cover",
      backgroundPosition: "top",
      display: "flex",
      justifyContent: "center",
      padding: 0,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        background:
          "linear-gradient(89.86deg, rgba(0, 5, 29, 0.56) 44.71%, rgba(196, 196, 196, 0) 158.87%)",
        zIndex: 1,
      },
      height: "calc(100vh - 79px)",
      alignItems: "center",
    },
    description: {
      color: Colors.copyTextGrey,
      fontWeight: 300,
      marginBottom: "24px",
    },
    mailLink: {
      fontFamily: "Helvetica Neue LT W05_65 Medium",
      fontWeight: 500,
    },
    secondaryDescription: {
      color: Colors.copyTextGrey,
      fontFamily: "Helvetica Neue LT W05_65 Medium",
      fontWeight: 500,
    },
  })
);
