import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "40px",
    width: "25%",
    height: "100%",
    padding: 0,
  },
  listItem: {
    marginRight: "0",
  },
  listItemText: {
    fontSize: "16px",
    lineHeight: "18,75px",
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: "bold",
    },
  },
}));
