import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../../constants/Style";

export const useStyles = makeStyles((theme: Theme) => ({
  redeemCoupon: {
    backgroundColor: Colors.paleDarkBlue,
    color: Colors.darkBlue,
    fontFamily: "Helvetica Neue LT W05_65 Medium",
    minWidth: 175,
    "& span": {
      paddingTop: theme.spacing(0.5),
    },
    "&:hover": {
      color: Colors.white,
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
      padding: theme.spacing(2, 1.5),
    },
  },
}));
