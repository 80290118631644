import { Box, Typography } from "@material-ui/core";
import { usePointsTranslation } from "../../utils/Translations";
import { useStyles } from "./PrizeListMobilePoints.styles";
interface Props {
  points: number;
}

export default function PrizeListMobilePoints({ points }: Props) {
  const classes = useStyles();
  const pointsTranslation = usePointsTranslation(points);

  return (
    <Box pt="14px" display="flex" flexDirection="row" alignItems="baseline">
      <Typography variant="h6" className={classes.bold}>
        {points}
      </Typography>
      <Box pr="4px" />
      <Typography variant="body2">{pointsTranslation}</Typography>
    </Box>
  );
}
