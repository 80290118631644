import { Box, ListItem, MenuList } from "@material-ui/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useStyles } from "./ProfileList.styles";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrowRight.svg";
import { ProfileListPropsType } from "../../../types/Components";
import { useProfileNavLinks } from "../../../utils/Links";
import { useAuth } from "../../../state/Auth";

const ProfileList: React.FC<ProfileListPropsType> = ({
  onClose,
  translationKey,
}) => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const profileNavLinks = useProfileNavLinks();

  const profileMenuItems = useMemo(
    () =>
      profileNavLinks.map(({ key, path, action }) => {
        if (!!action && action === "logout") {
          return (
            <ListItem
              key={`${key}-list-item`}
              disableGutters
              className={classes.item}
              onClick={() => {
                onClose();
                logout();
              }}
            >
              <NavLink
                to={path}
                activeClassName={classes.active}
                className={classes.link}
              >
                {t(key)}
                <ArrowRightIcon />
              </NavLink>
            </ListItem>
          );
        }

        return (
          <ListItem
            key={key}
            disableGutters
            className={classes.item}
            onClick={onClose}
          >
            <NavLink
              to={path}
              activeClassName={classes.active}
              className={classes.link}
            >
              {t(key)}
              <ArrowRightIcon />
            </NavLink>
          </ListItem>
        );
      }),
    [
      classes.active,
      classes.item,
      classes.link,
      logout,
      onClose,
      profileNavLinks,
      t,
    ]
  );

  return (
    <Box className={classes.root}>
      <MenuList className={classes.list}>{profileMenuItems}</MenuList>
    </Box>
  );
};
export default ProfileList;
