import { useCallback, useMemo, useState } from "react";
import { Box, IconButton, TextField } from "@material-ui/core";
import { useStyles } from "./QuantityField.styles";
import { ReactComponent as ArrowUp } from "../../assets/icons/quantity-arrow.svg";
import clsx from "clsx";

interface Props {
  quantity: number;
  onChange: (quantity: number) => void;
  hasMobileClassName?: boolean;
}
const QuantityField = ({ quantity, onChange, hasMobileClassName }: Props) => {
  const classes = useStyles();
  const [input, setInput] = useState<number | "">(quantity);

  const inputHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value !== "" && value !== "0") {
        const intVal = Math.abs(parseInt(value));
        setInput(intVal);
        onChange(intVal);
      } else setInput("");
    },
    [onChange, setInput]
  );

  const editBreakHandler = useCallback(() => {
    if (quantity !== input && input !== "") {
      onChange(input);
    } else if (quantity !== input && input === "") {
      onChange(1);
      setInput(1);
    }
  }, [input, quantity, onChange]);

  const increaseCount = useCallback(() => {
    setInput(quantity + 1);
    onChange(quantity + 1);
  }, [onChange, quantity]);

  const decreaseCount = useCallback(() => {
    if (quantity > 1) {
      setInput(quantity - 1);
      onChange(quantity - 1);
    }
  }, [onChange, quantity]);

  const quantityArrows = useMemo(
    () => (
      <Box display="flex" flexDirection="column">
        <IconButton className={classes.buttonUp} onClick={increaseCount}>
          <ArrowUp />
        </IconButton>
        <IconButton className={classes.buttonDown} onClick={decreaseCount}>
          <ArrowUp className={classes.arrowDown} />
        </IconButton>
      </Box>
    ),
    [
      increaseCount,
      decreaseCount,
      classes.buttonUp,
      classes.buttonDown,
      classes.arrowDown,
    ]
  );

  return (
    <TextField
      onBlur={editBreakHandler}
      variant="outlined"
      type="number"
      className={clsx({
        [classes.input]: true,
        [classes.mobileInput]: !!hasMobileClassName,
      })}
      inputProps={{ min: 1 }}
      value={input}
      onChange={inputHandler}
      InputProps={{
        endAdornment: quantityArrows,
      }}
    />
  );
};

export default QuantityField;
