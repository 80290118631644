import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transparentText: {
      "& .MuiButton-label": {
        color: "transparent",
      },
    },
    progress: {
      position: "absolute",
    },
  })
);
