import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import FullWidthLayout from "../../components/FullWidthLayout/FullWidthLayout";
import Header from "../../components/Header/Header";

import { useStyles } from "./ErrorBoundary.styles";

interface IProps {}
interface IErrorBoundary {
  hasError: boolean;
}

const ErrorBoundaryBody = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Header growMenu={false} />
      <FullWidthLayout className={classes.rootContainer} disableBorder>
        <Box
          paddingY="80px"
          paddingX="60px"
          bgcolor="white"
          maxWidth="490px"
          flexDirection="column"
          marginTop={isMobile ? 0 : "80px"}
          marginBottom={isMobile ? 0 : 10}
          textAlign="center"
        >
          <Typography variant="h2" color="primary" style={{ marginBottom: 32 }}>
            {t("error_boundary.title")}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {t("error_boundary.description_first")}
            <Link
              href={`mailto:${t("footer.contact_email")}`}
              variant="button"
              className={classes.mailLink}
            >
              {t("footer.contact_email")}
            </Link>
            {t("error_boundary.description_second")}
          </Typography>
          <Typography variant="body1" className={classes.secondaryDescription}>
            {t("error_boundary.secondary_description")}
          </Typography>
        </Box>
      </FullWidthLayout>
    </>
  );
};

export class ErrorBoundary extends React.Component<IProps, IErrorBoundary> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryBody />;
    }

    return this.props.children;
  }
}
