import {
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useMemo } from "react";
import { useStyles } from "./PrizeListDesktop.styles";
import { useTranslation } from "react-i18next";
import PrizeListItem from "./PrizeListItem/PrizeListItem";
import { PrizeList } from "../../types/Prizes";

interface IProps {
  thead: string[];
  type: "cart" | "favorites";
  prizes: PrizeList[];
  addToHandler: (id: number, title?: string) => void;
  handleDelete: (id: number, title: string) => void;
  handleChangeQuantity?: (quantity: number, id: number) => void;
}

export default function PrizeListDesktop(props: IProps) {
  const {
    thead,
    prizes,
    handleDelete,
    handleChangeQuantity,
    addToHandler,
    type,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const HeadCells = useMemo(() => {
    return thead?.map((title, i) => (
      <TableCell key={`th-cell-${i}`} className={classes.tableHeadCell}>
        <Typography
          style={{
            textTransform: title === "common.points" ? "capitalize" : undefined,
          }}
          variant="h5"
          color="textSecondary"
        >
          {t(title)}
        </Typography>
      </TableCell>
    ));
  }, [thead, t, classes.tableHeadCell]);

  const BodyRows = useMemo(() => {
    return prizes?.map((item) => (
      <PrizeListItem
        key={item?.id}
        prize={item}
        addToHandler={addToHandler}
        handleDelete={handleDelete}
        type={type}
        handleChangeQuantity={handleChangeQuantity && handleChangeQuantity}
      />
    ));
  }, [prizes, addToHandler, handleChangeQuantity, handleDelete, type]);

  return (
    <Hidden smDown>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.container}
      >
        <Table>
          <TableHead>
            <TableRow>{HeadCells}</TableRow>
          </TableHead>
          <TableBody>{BodyRows}</TableBody>
        </Table>
      </TableContainer>
    </Hidden>
  );
}
