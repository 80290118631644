import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCoupon } from "../../../state/RedeemCoupon";
import { CouponField } from "../../CouponField/CouponField";
import DialogLayout from "../../Dialogs/DialogLayout/DialogLayout";
import SuccessPopup from "../../Dialogs/SuccessPopup/SuccessPopup";

import { useStyles } from "./RedeemCoupon.styles";

export const RedeemCoupon = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState("");
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const {
    applyCoupon,
    loadingCoupon,
    couponError,
    clearCoupon,
    appliedCoupon,
  } = useCoupon();

  useEffect(() => {
    if (appliedCoupon) {
      setShowCouponPopup(false);
      setCouponCode("");
      clearCoupon();
      setShowSuccessPopup(true);
    }
  }, [appliedCoupon, clearCoupon]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.redeemCoupon}
        onClick={() => setShowCouponPopup(true)}
      >
        {t("buttons.use_coupon")}
      </Button>
      <DialogLayout
        title={t("invoice.enter_coupon_code")}
        subTitle={t("invoice.apply_coupon_description")}
        open={showCouponPopup}
        onClose={() => {
          setShowCouponPopup(false);
          setCouponCode("");
          clearCoupon();
        }}
      >
        <CouponField
          onInputChange={setCouponCode}
          onSubmit={() => applyCoupon(couponCode)}
          value={couponCode}
          loading={loadingCoupon}
          error={couponError}
        />
      </DialogLayout>
      <SuccessPopup
        open={showSuccessPopup}
        onClose={() => setShowSuccessPopup(false)}
        onSubmit={() => setShowSuccessPopup(false)}
        title={t("invoice.applied_coupon_success")}
        buttonText={t("buttons.continue")}
      />
    </>
  );
};

export default RedeemCoupon;
