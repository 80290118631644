import { Badge, Box, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { useStyles } from "./ActionIcon.styles";
import { useHeaderUtil } from "../../../utils/Header";

interface IProps {
  isActive: boolean;
  type: "bag" | "favorites" | "user" | "languages";
  handleIconClick: (e: any) => void;
  handleHover: (hover: boolean) => void;
  badge: boolean;
  badgeContent?: number;
  noSpacing?: boolean;
}

export const ActionIcon = (props: IProps) => {
  const {
    handleIconClick,
    isActive,
    badge,
    badgeContent = 0,
    type,
    handleHover,
    noSpacing,
  } = props;
  const classes = useStyles(isActive);
  const { getIconByType, getFullIconByType } = useHeaderUtil();

  const Icon = useMemo(() => getIconByType(type), [getIconByType, type]);

  const ActiveIcon = useMemo(
    () => getFullIconByType(type),
    [getFullIconByType, type]
  );

  const activeLineOpacity = useMemo(
    () => (isActive ? { fillOpacity: "100%" } : { fillOpacity: "0%" }),
    [isActive]
  );

  const inactiveLineOpacity = useMemo(
    () => (isActive ? { fillOpacity: "0%" } : { fillOpacity: "100%" }),
    [isActive]
  );

  const activeIconStyle = useMemo(() => {
    switch (type) {
      case "favorites":
        return { marginTop: -1, ...activeLineOpacity };
      default:
        return { ...activeLineOpacity };
    }
  }, [activeLineOpacity, type]);

  const Icons = useMemo(
    () => (
      <Box height="24px" width="24px">
        {!!Icon && (
          <Icon
            className={clsx({
              [classes.svg]: true,
            })}
            style={inactiveLineOpacity as any}
          />
        )}
        {!!ActiveIcon && (
          <ActiveIcon
            className={clsx({
              [classes.svg]: true,
            })}
            style={activeIconStyle as any}
          />
        )}
      </Box>
    ),
    [ActiveIcon, Icon, activeIconStyle, classes.svg, inactiveLineOpacity]
  );

  const IconWithBadge = useMemo(() => {
    if (badge)
      return (
        <Badge
          badgeContent={badgeContent}
          classes={{ badge: classes.badge }}
          className={clsx({
            [`${classes.largeCountBadge}`]: badgeContent >= 10,
          })}
        >
          {Icons}
        </Badge>
      );

    return Icons;
  }, [badge, badgeContent, classes.badge, classes.largeCountBadge, Icons]);

  return (
    <Box className={noSpacing ? undefined : classes.wrapper}>
      <IconButton
        disableFocusRipple
        disableRipple
        onClick={(e) => handleIconClick(e)}
        onMouseOver={() => handleHover(true)}
        onMouseOut={() => handleHover(false)}
        onMouseLeave={() => handleHover(false)}
        className={clsx({
          [`${classes.icon}`]: true,
          [`${classes.activeIcon} ${classes.badgeIcon}`]: isActive,
        })}
      >
        {IconWithBadge}
      </IconButton>
    </Box>
  );
};
