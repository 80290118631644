import { useCallback, useMemo, useState } from "react";
import useSWR from "swr";
import { Endpoints } from "../api/endpoints";
import {
  ProfileFormData,
  UseProfileBriefInfo,
  UseProfileData,
  UseProfileManager,
} from "../types/Profile";
import { mutateData } from "../api/api";
import { useAuth } from "./Auth";
import { CropFormObject } from "../types/Crops";
import { Roles } from "../constants/roles";

export const useProfileBriefInfo = (): UseProfileBriefInfo => {
  const { isLoggedIn } = useAuth();
  const { data: profile, error: profileError } = useSWR(
    isLoggedIn ? Endpoints.profileBriefInfo : null
  );

  const loading = useMemo(
    () => !profile && !profileError,
    [profile, profileError]
  );

  const isBasfUser = useMemo(() => {
    if (loading || !profile?.roles) return false;
    return profile?.roles?.includes(Roles.basfUser);
  }, [loading, profile?.roles]);

  const isDistributorUser = useMemo(() => {
    if (loading || !profile?.roles) return false;
    return profile?.roles?.includes(Roles.distributor);
  }, [loading, profile?.roles]);

  return {
    profile,
    loading,
    profileError,
    isBasfUser,
    isDistributorUser,
  };
};

export const useProfileData = (): UseProfileData => {
  const { isLoggedIn } = useAuth();
  const { isBasfUser } = useProfileBriefInfo();
  const {
    data: userData,
    error: userDataError,
    mutate: mutateProfile,
  } = useSWR(isLoggedIn ? Endpoints.profileData : null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loading = useMemo(
    () => !userData && !userDataError,
    [userData, userDataError]
  );

  const parsedUserData = useMemo(() => {
    if (!userData) return null;
    const stringifiedCrops =
      userData?.userCrops?.map(({ crop, area }: CropFormObject) => ({
        area,
        crop: {
          id: crop?.id?.toString(),
          name: crop?.name,
        },
      })) ?? [];
    return {
      ...userData,
      userType: userData?.userType?.toString(),
      userCrops: stringifiedCrops,
    };
  }, [userData]);

  const parseDataForRequest = useCallback(
    (formData: ProfileFormData) => {
      const userCrops = formData.userCrops
        ?.map((item) => ({
          area: item.area,
          crop: item.crop.id,
        }))
        ?.filter((item) => !!item.crop);
      const data = { ...formData };
      delete data.userCrops;
      const filtered = Object.keys(data)
        .filter((key) => !!key)
        .reduce((item: any, key) => {
          item[key as keyof ProfileFormData] =
            data[key as keyof ProfileFormData];
          return item;
        }, {});
      return {
        ...filtered,
        UserCrops: isBasfUser ? userCrops : undefined,
        municipality: data.municipality.id,
      };
    },
    [isBasfUser]
  );

  const editProfile = useCallback(
    async (formData: ProfileFormData) => {
      setIsLoading(true);
      const data = parseDataForRequest(formData);
      const url = isBasfUser
        ? Endpoints.editProfile
        : Endpoints.editDistributorProfile;

      return mutateData("put", url, data)
        .then(async () => {
          await mutateProfile();
          return {
            success: true,
          };
        })
        .catch((e) => {
          return e?.response?.data;
        })
        .finally(() => setIsLoading(false));
    },
    [isBasfUser, mutateProfile, parseDataForRequest]
  );

  return {
    userData: parsedUserData,
    loading: loading,
    userDataError,
    isLoading,
    editProfile,
  };
};

export const useProfileManager = (isEE: boolean): UseProfileManager => {
  // fetch if locale is EE only
  const { data: profileManager, error: profileManagerError } = useSWR(
    isEE ? Endpoints.profileManager : null
  );

  const loading = useMemo(
    () => !profileManager && !profileManagerError,
    [profileManager, profileManagerError]
  );

  return {
    profileManager,
    loading,
    profileManagerError,
  };
};
