import { Box, ListItem, MenuList } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Link } from "../../../types/Common";
import { useStyles } from "./NavPopperContent.styles";
import { usePrizes } from "../../../state/Prizes";

interface NavPopperContentType {
  subroutes: Array<Link[]>;
  closePopover: () => void;
}
const NavPopperContent: React.FC<NavPopperContentType> = ({
  subroutes,
  closePopover,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { clearAllPrizeFilters } = usePrizes();

  const handleClick = useCallback(
    (path: string) => () => {
      clearAllPrizeFilters();
      history.push(path);
      closePopover();
    },
    [closePopover, history, clearAllPrizeFilters]
  );

  const subrouteMap = useMemo(
    () =>
      subroutes?.map((subroute, i) => (
        <Box key={i} className={classes.gridItem}>
          <MenuList>
            {subroute?.map(({ key, path }) => (
              <ListItem
                className={classes.root}
                disableGutters={true}
                key={key}
                onClick={handleClick(path)}
              >
                <NavLink
                  to={path}
                  activeClassName={classes.active}
                  className={classes.link}
                  title={key}
                  exact
                >
                  {key}
                </NavLink>
              </ListItem>
            ))}
          </MenuList>
        </Box>
      )),
    [
      subroutes,
      classes.gridItem,
      classes.root,
      classes.active,
      classes.link,
      handleClick,
    ]
  );

  return <Box display="flex">{subrouteMap}</Box>;
};
export default NavPopperContent;
