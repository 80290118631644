import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./QuestionSent.styles";
import { ReactComponent as SentIcon } from "../../../assets/icons/checkBox.svg";
import { useTranslation } from "react-i18next";

const QuestionSent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        <SentIcon />
      </Box>
      <Typography className={classes.text}>
        {t("form.question_sent")}
      </Typography>
    </Box>
  );
};
export default QuestionSent;
