import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: "0",
    "&:hover": {
      textDecoration: "none",
      "& $itemText": {
        fontWeight: "bold",
      },
    },
  },
  itemText: {
    fontSize: "16px",
    lineHeight: "18,75px",
    color: theme.palette.primary.main,
    "&::before": {
      display: "block",
      content: "attr(title)",
      fontWeight: "bold",
      height: 0,
      overflow: "hidden",
      visibility: "hidden",
    },
  },
  itemIcon: {
    transition: ".3s all",
    color: theme.palette.primary.main,
  },
  active: {
    fontWeight: "bold",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: 0,
    borderBottom: "3px solid rgba(0,74,150,1)",
  },
  listItemIcon: {
    minWidth: "auto",
  },
  popper: {
    zIndex: 9,
    left: "-24px!important",
  },
}));
