import { useMemo } from "react";
import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./PrizeListMobile.styles";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { useTranslation } from "react-i18next";
import QuantityField from "../QuantityField/QuantityField";
import { CartPrize } from "../../types/Cart";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as FullHeartIcon } from "../../assets/icons/full-heart.svg";
import { ReactComponent as CartIcon } from "../../assets/icons/cart.svg";
import PrizeListMobilePoints from "../PrizeListMobilePoints/PrizeListMobilePoints";
import { useGetImageUrl } from "../../hooks/useImageUrl";

interface Props {
  prizes: CartPrize[];
  handleDelete: (id: number, title: string) => void;
  addToHandler: (id: number, title?: string, prizeId?: string) => void;
  type: "cart" | "favorites";
  deleteFromFavorites?: (id: number) => void;
  handleChangeQuantity?: (quantity: number, id: number) => void;
}

export default function PrizeListMobile(props: Props) {
  const {
    prizes,
    handleChangeQuantity,
    handleDelete,
    type,
    addToHandler,
    deleteFromFavorites,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const getImageUrl = useGetImageUrl();

  const QuantityLabel = useMemo(() => {
    if (!handleChangeQuantity) return null;

    return (
      <Box pt="11px">
        <Typography variant="subtitle2" color="textSecondary">
          {t("cart.quantity")}:
        </Typography>
      </Box>
    );
  }, [handleChangeQuantity, t]);

  return (
    <Hidden mdUp>
      {prizes?.map((item, index) => (
        <Box key={index}>
          <Box className={classes.deleteIconContainer}>
            <IconButton
              onClick={() => handleDelete(item?.id, item.prize.title)}
            >
              <Delete className={classes.deleteIcon} />
            </IconButton>
          </Box>
          <Grid container className={classes.cardContainer}>
            <Grid item xs={5}>
              <CardMedia
                title={item.prize.title}
                image={getImageUrl(item.prize.images[0]) as string}
                className={classes.image}
              />
            </Grid>
            <Grid item xs={7}>
              <Box pl={2}>
                <Typography className={classes.title}>
                  {item.prize.title}
                  <br />
                  {item.prize.subTitle}
                </Typography>
                {QuantityLabel}
                {handleChangeQuantity && deleteFromFavorites && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <QuantityField
                      onChange={(quantity: number) =>
                        handleChangeQuantity(quantity, item?.id)
                      }
                      quantity={item?.totals?.itemQuantity}
                      hasMobileClassName={true}
                    />
                    <IconButton
                      onClick={() =>
                        item?.prize?.isFavorite
                          ? deleteFromFavorites(item?.prize?.id)
                          : addToHandler(item?.id, item.prize.title)
                      }
                      className={classes.favoriteBtn}
                    >
                      {item?.prize?.isFavorite ? (
                        <FullHeartIcon />
                      ) : (
                        <HeartIcon />
                      )}
                    </IconButton>
                  </Box>
                )}

                <PrizeListMobilePoints points={item?.totals?.amountInPoints} />

                {type === "favorites" && (
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<CartIcon />}
                    className={classes.addToCartBtn}
                    disabled={item?.prize?.outOfStock}
                    onClick={() =>
                      addToHandler(
                        item?.id,
                        item?.prize?.title,
                        String(item?.prize?.id)
                      )
                    }
                  >
                    {t(
                      item?.prize?.outOfStock
                        ? "buttons.out_of_stock"
                        : "buttons.add_to_bag"
                    )}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Box>
      ))}
    </Hidden>
  );
}
