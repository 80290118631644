import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      padding: "16px 24px 16px 24px",
      marginTop: 8,
      width: "281px",
      borderBottom: "3px solid rgba(0,74,150,1)",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
      [theme.breakpoints.down("xs")]: {
        border: "none",
        width: "100%",
        padding: "0 32px 12px 32px",
      },
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        padding: "12px 0 0 0",
      },
    },
    item: {
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.03em",
      padding: "16px 0",
      [theme.breakpoints.down("xs")]: {
        padding: "20px 0",
      },
    },

    link: {
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.03em",
      textDecoration: "none",
      color: "black",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": {
        fontWeight: "bold",
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  })
);
