import { useLocation } from "react-router-dom";
import { format, fromUnixTime } from "date-fns";

export const exampleUtil = () => {
  return "This is an example of a Pure Javascript function";
};

export const formatUnixDate = (date: number) => {
  return format(fromUnixTime(date), "yyyy-MM-dd");
};

export const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const numberToArray = (number: number) => {
  return Array.from({ length: number }, (_, i) => i + 1);
};

export const parseFileSize = (bytes: number, decimals: number) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const disableBodyScroll = () => {
  document.body.style.position = "fixed";
  document.body.style.overflowY = "scroll";
};

export const enableBodyScroll = () => {
  document.body.style.removeProperty("position");
  document.body.style.removeProperty("overflow");
};

export const validateEmail = (email: string) => {
  const regex =
    "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])";
  return !!email.match(regex)?.length;
};

export function convertToSlug(text: string) {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export const replaceString = (value: string, key: string, result: string) => {
  return value ? value.replaceAll(key, result) : "";
};

// preloads the first frame of a video as a thumbnail
export const appendUrl = (url: string) => {
  return `${url}#t=0.1`;
};
