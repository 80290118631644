import { Paper, Divider } from "@material-ui/core";
import { Link } from "../../types/Common";
import { useStyles } from "./NavPopper.styles";
import NavPopperContent from "./NavPopperContent/NavPopperContent";
import NavPopperTitle from "./NavPopperTitle/NavPopperTitle";

interface NavPopperType {
  translationKey: string;
  children: Array<Link[]>;
  closePopover: () => void;
  onTitleClick?: () => void;
  hideTitle?: boolean;
}

function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    (!!navigator?.maxTouchPoints && navigator.maxTouchPoints > 0)
  );
}

const NavPopper: React.FC<NavPopperType> = ({
  translationKey,
  children,
  closePopover,
  onTitleClick,
  hideTitle = false,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={1} square className={classes.root}>
      {!hideTitle && (
        <>
          <Divider />
          <NavPopperTitle
            translationKey={translationKey}
            onClick={isTouchDevice() ? onTitleClick : undefined}
          />
          <Divider />
        </>
      )}
      <NavPopperContent closePopover={closePopover} subroutes={children} />
    </Paper>
  );
};
export default NavPopper;
